import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseSpatialMap from "components/spatial/base/BaseSpatialMap";
import { flow, Lazy, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import * as TO from "fp-ts/TaskOption";
import * as E from "fp-ts/Either";
import * as Sl from "lib/at-data/Slice";
import { promisify } from "es6-promisify";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import {
  feature,
  featureCollection,
  getGeom,
  point,
  radiansToDegrees,
} from "@turf/turf";
import { Feature, FeatureCollection, Point } from "geojson";
import { getCenter } from "lib/at-api/assets/legacy/ZoneResponseModel";
import * as R from "fp-ts/lib/Reader";
import * as b from "fp-ts/lib/boolean";
import * as RT from "fp-ts/lib/ReaderT";
import { addLayerToMap } from "components/spatial/base/layers/assetPolygon";
import * as IO from "fp-ts/IO";
import { noop } from "lib/util";
import { addOrUpdateFloorDiagramLayer } from "views/authenticated/assets/page/components/AssetPreviewMap/AssetPreviewMap";
import palette from "theme/palette";
import {
  ByTag,
  filterAssets,
  getAllChildren,
  getBuildings,
} from "lib/at-data/assets/filters";
import {
  apFirstIO,
  apSecondIO,
  chainFirstIO,
} from "views/authenticated/activity/page/components/ReaderIO";
import {
  assetScaleForBBox,
  getAssetBBox,
  getAssetsBBox,
  scaleBBox,
} from "views/authenticated/assets/page/components/AssetPreviewMap/actions/asset";
import { FLOOR_DIAGRAM_LAYER_ID } from "components/spatial/base/layers/floorDiagram";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { renderDetailedHeatmap } from "components/spatial/base/layers/heatmap";
import {
  MapLayersContext,
  withAssets,
  withFloorDiagramContext,
  withLayersConfig,
} from "views/authenticated/assets/page/components/AssetPreviewMap/util";
import {
  handleLayerEvent,
  hideLayer,
  hideLayers,
  showLayer,
  showLayers,
} from "lib/fp-mapbox/layers";
import {
  MapboxContext,
  MapBoxPaddingOptionsContext,
  withPaddingOptions,
} from "lib/fp-mapbox/MapboxContext";
import {
  addOrUpdateGeoJSONSourceOld,
  getOrAddGeoJSONSource,
  updateGeoJSONSource,
} from "lib/fp-mapbox/sources/geojson";
import { onUserMapMove2 } from "lib/fp-mapbox/stage";
import { Asset } from "lib/at-data/assets/Asset";
import {
  UtilizationContext,
  withUtilizationContext,
} from "contexts/OccupancyEstimateContext";
import { getUtilization, Utilization } from "lib/at-data/Utilization";
import * as N from "lib/at-data/NumberSlice";
import {
  ACTIVITY_MAP_LAYER,
  mapLayersEq,
} from "views/authenticated/activity/controller/state";
import { getSource } from "lib/fp-mapbox/sources";
import { EventData, Map } from "mapbox-gl";
import { setMapBBox } from "views/authenticated/assets/page/components/AssetPreviewMap/actions/map";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import {
  ActivityPageSelectedAssetIdL,
  ActivityPageUIStateL,
  BboxL,
  BearingL,
  SelectedSideItemL,
} from "controllers/UIStateCollector/lens/activityPage";
import * as L from "monocle-ts/Lens";
import {
  ActivityMapFloorDiagramDataController,
  floorPlanImageL,
} from "controllers/ActivityMapFloorDiagramController/ActivityMapFloorDiagramController";
import { toPolygonsGeoJSON } from "views/authenticated/activity/page/components/ToPolygonsGeoJSON";
import { MAPBOX_DEFAULT_LAYERS } from "views/authenticated/activity/page/components/MAPBOX_DEFAULT_LAYERS";
import { BBox } from "lib/at-data/BBox";
import { AssetsL } from "views/authenticated/root/controller/state";
import { getData } from "lib/at-data/AsyncData";
import {
  ActivityMapDataController,
  UtilizationGeoJSONL,
} from "controllers/ActivityMapDataController/ActivityMapDataController";
import { setupLoadingIndicatorLayer } from "components/spatial/base/layers/loading";
import { runMapEffect } from "lib/fp-mapbox/effects/runeMapEffect";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import { AssetPageTab } from "controllers/UIStateCollector/models/AssetsPageTab";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import { warn, log } from "fp-ts/Console";
import * as AD from "lib/at-data/AsyncData";
import { ActivityPageUICalculationsController } from "controllers/UIStateContext/UIStateContext";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { useCollector } from "lib/at-react/hooks";
import { SelectedAssetHierarchyCollector } from "controllers/SelectedAssetHierarchyCollector/SelectedAssetHierarchyCollector";
import { Assets } from "lib/at-data/assets/assets";
import * as S from "fp-ts/Set";
import { getGeometry, getId } from "lib/at-data/assets/getters";

export const EmptyGeoJSON: FeatureCollection = {
  type: "FeatureCollection",
  features: [],
};

export const toClustersGeoJSON = (assets: Assets) =>
  pipe(
    assets,
    A.map((as) =>
      pipe(
        as,
        getGeometry,
        O.map((p) =>
          pipe(p, getCenter, point, getGeom, (geom) =>
            feature(geom, { polygon: A.of(p) })
          )
        )
      )
    ),
    A.sequence(O.Monad),
    O.map(featureCollection),
    O.getOrElse(() => EmptyGeoJSON)
  );

export const getMaxUtilization = (assetsSlice: UUIDSlice<Utilization>) => {
  const max = pipe(assetsSlice, Sl.map(getUtilization), N.max);
  return max > 0 ? max : 1;
};

export const updateZoneUtilizationLayer = pipe(
  R.ask<UtilizationContext>(),
  R.chainW(
    flow(
      (_) => _.utilizationSlice,
      getData,
      O.map(featureCollection),
      O.fold(
        () => R.of(noop),
        (features) => pipe(updateGeoJSONSource("room_by_room_source", features))
      )
    )
  )
);

export const getClusterExpansionZoom = (sourceId: string, clusterId: string) =>
  pipe(
    getSource(sourceId),
    R.map(
      flow(
        TO.of,
        TO.chain((js: any) => {
          return pipe(
            TE.tryCatch(
              promisify(js.getClusterExpansionZoom.bind(js, clusterId)) as Lazy<
                Promise<number>
              >,
              E.toError
            ),
            TO.fromTaskEither
          );
        })
      )
    )
  );

export const setupClusterLayer = pipe(
  R.of(EmptyGeoJSON),
  R.chain(
    getOrAddGeoJSONSource("asset_cluster_source", {
      cluster: true,
      clusterRadius: 80,
    })
  ),
  chainFirstIO(
    pipe(
      addLayerToMap("asset_cluster_layer", {
        id: "asset_cluster_layer",
        type: "circle",
        source: "asset_cluster_source",
        layout: {},
        maxzoom: 14,
        paint: {
          "circle-color": palette.primary[500],
          "circle-radius": 10,
          "circle-stroke-color": palette.neutral[600],
          "circle-stroke-width": 8,
        },
      }),
      RT.chain(IO.Monad)(
        handleLayerEvent("click", ({ map }) => (e) => {
          const selectedFeatures = pipe(
            map.queryRenderedFeatures(e.point, {
              layers: ["asset_cluster_layer"],
            }),
            NEA.fromArray,
            O.map(NEA.head)
          );

          const clusterId = pipe(
            selectedFeatures,
            O.chain(
              flow(
                (f) => O.fromNullable(f.properties),
                O.chain((f) => O.fromNullable(f.cluster_id as string))
              )
            )
          );

          // pipe(
          //   selectedFeatures as O.Option<Feature<Point, { polygon: string }>>,
          //   O.fold(
          //     () => T.never,
          //     (sf) => {
          //       return pipe(
          //         clusterId,
          //         TO.fromOption,
          //         TO.foldW(
          //           () =>
          //             T.fromIO(
          //               fitBoundsSmooth(
          //                 pipe(
          //                   //TODO: make this more durable
          //                   JSON.parse(sf.properties.polygon) as Position[][],
          //                   toBounds
          //                 )
          //               )({ map })
          //             ),
          //           (cid) => TO.of(noop)
          //         )
          //       );
          //     }
          //   )
          // )();
        })
      )
    )
  )
);

export const updateClusterLayer = (assets: Assets) =>
  pipe(
    assets,
    toClustersGeoJSON,
    R.of,
    R.chain(
      getOrAddGeoJSONSource("asset_cluster_source", {
        cluster: true,
        clusterRadius: 80,
      })
    ),
    apFirstIO(
      pipe(
        addLayerToMap("asset_cluster_layer", {
          id: "asset_cluster_layer",
          type: "circle",
          source: "asset_cluster_source",
          layout: {},
          maxzoom: 14,
          paint: {
            "circle-color": palette.primary[500],
            "circle-radius": 10,
            "circle-stroke-color": palette.neutral[600],
            "circle-stroke-width": 8,
          },
        })
      )
    )
  );

export const onBuildingClick = (
  buildingClickHandler: (selectedAsset: O.Option<Asset>) => void
) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.on("click", "building_polygon_layer", (e) => {
        const selectedFeature = pipe(
          ctx.map.queryRenderedFeatures(e.point),
          NEA.fromArray,
          O.map(NEA.head)
        );
        pipe(
          selectedFeature as O.Option<
            Feature<Point, { assetId: string; asset: string }>
          >,
          O.fold(
            () => noop(),
            (sf) =>
              buildingClickHandler(
                O.some(JSON.parse(sf.properties.asset) as Asset)
              )
          )
        );
      });
    })
  );

export const setupBuildingLayer = (promoteId: string) =>
  pipe(
    R.of(EmptyGeoJSON),
    R.chain(
      getOrAddGeoJSONSource("building_polygon_source", {
        promoteId,
      })
    ),
    chainFirstIO(
      addLayerToMap("building_outline_layer", {
        id: "building_outline_layer",
        type: "line",
        source: "building_polygon_source",
        layout: {},
        paint: {
          "line-color": palette.primary[400],
          // "line-width": 4,
          "line-width": ["case", ["boolean", ["get", "selected"], false], 4, 0],
        },
      })
    ),
    chainFirstIO(
      pipe(
        addLayerToMap("building_polygon_layer", {
          id: "building_polygon_layer",
          type: "fill",
          source: "building_polygon_source",
          layout: {},
          minzoom: 10,
          paint: {
            "fill-color": palette.primary[600],
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              0.7,
              1,
            ],
          },
        })
      )
    ),
    chainFirstIO(
      pipe(
        R.ask<MapboxContext>(),
        R.map((ctx) => () => {
          let hoveredStateId: any = null;

          ctx.map.on(
            "mousemove",
            // @ts-ignore
            "building_polygon_layer",
            (e) => {
              if (e?.features?.length) {
                if (hoveredStateId) {
                  ctx.map.setFeatureState(
                    { source: "building_polygon_source", id: hoveredStateId },
                    { hover: false }
                  );
                }
                hoveredStateId = e.features[0].id;
                ctx.map.setFeatureState(
                  { source: "building_polygon_source", id: hoveredStateId },
                  { hover: true }
                );
              }
            }
          );
          ctx.map.on(
            "mouseleave",
            // @ts-ignore
            "building_polygon_layer",
            (e) => {
              if (hoveredStateId) {
                ctx.map.setFeatureState(
                  { source: "building_polygon_source", id: hoveredStateId },
                  { hover: false }
                );
                hoveredStateId = null;
              }
            }
          );
        })
      )
    )
  );
export const updateBuildingLayer = (
  assets: Assets,
  selectedAsset: O.Option<Asset>
) =>
  pipe(
    R.of(
      toPolygonsGeoJSON(
        assets,
        pipe(selectedAsset, O.map(getId), O.toUndefined)
      )
    ),
    R.chain(getOrAddGeoJSONSource("building_polygon_source"))
  );

export const setupFloorAssetsLayer = (
  assetClickHandler: (selectedAsset: O.Option<Asset>) => void
) =>
  pipe(
    R.of(EmptyGeoJSON),
    R.chain(
      getOrAddGeoJSONSource("floor_polygon_source", {
        promoteId: "assetId",
      })
    ),
    apFirstIO(
      addLayerToMap("all_floor_assets_fill_layer", {
        id: "all_floor_assets_fill_layer",
        type: "fill",
        source: "floor_polygon_source",
        layout: {},
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.5,
          // "line-color": palette.status.warning.dark,
          // // "line-width": 4,
          // "line-width": 4,
        },
      })
    ),
    apFirstIO(
      addLayerToMap("all_floor_assets_outline_layer", {
        id: "all_floor_assets_outline_layer",
        type: "line",
        source: "floor_polygon_source",
        layout: {},
        paint: {
          "line-color": palette.status.warning.dark,
          "line-width": 4,
        },
      })
    ),
    apFirstIO(
      addLayerToMap("all_all_floor_assets_id_layer", {
        id: "all_floor_assets_id_layer",
        type: "symbol",
        source: "floor_polygon_source",
        layout: {
          "text-field": ["upcase", ["get", "code"]],
          "text-size": 24,
          "text-font": ["Roboto Mono Regular"],
          "text-allow-overlap": true,
        },
        paint: { "text-color": palette.neutral["100"] },
      })
    ),
    apFirstIO(
      pipe(
        addLayerToMap("floor_assets_fill_layer", {
          id: "floor_assets_fill_layer",
          type: "fill",
          source: "floor_polygon_source",
          layout: {},
          paint: {
            // "fill-color": ["get", "color"],
            // "fill-opacity": 0.5,
            "fill-color": palette.primary[400],
            "fill-opacity": [
              "case",
              ["boolean", ["get", "selected"], true],
              1,
              [
                "case",
                ["boolean", ["to-boolean", ["feature-state", "hover"]], true],
                0.5,
                0,
              ],
            ],
          },
        }),
        chainFirstIO(
          pipe(
            R.ask<MapboxContext>(),
            R.map((ctx) => () => {
              let hoveredStateId: any = null;

              ctx.map.on(
                "mousemove",
                // @ts-ignore
                "floor_assets_fill_layer",
                (e) => {
                  if (e?.features?.length) {
                    if (hoveredStateId) {
                      ctx.map.setFeatureState(
                        { source: "floor_polygon_source", id: hoveredStateId },
                        { hover: false }
                      );
                    }
                    hoveredStateId = e.features[0].id;
                    ctx.map.setFeatureState(
                      { source: "floor_polygon_source", id: hoveredStateId },
                      { hover: true }
                    );
                  }
                }
              );
              ctx.map.on(
                "mouseleave",
                // @ts-ignore
                "floor_assets_fill_layer",
                (e) => {
                  if (hoveredStateId) {
                    ctx.map.setFeatureState(
                      { source: "floor_polygon_source", id: hoveredStateId },
                      { hover: false }
                    );
                    hoveredStateId = null;
                  }
                }
              );

              ctx.map.on("click", "floor_assets_fill_layer", (e) => {
                const selectedFeature = pipe(
                  ctx.map.queryRenderedFeatures(e.point),
                  NEA.fromArray,
                  O.map(NEA.head)
                );
                pipe(
                  selectedFeature as O.Option<
                    Feature<Point, { assetId: string; asset: string }>
                  >,
                  O.fold(
                    () => noop(),
                    (sf) =>
                      assetClickHandler(
                        O.some(JSON.parse(sf.properties.asset) as Asset)
                      )
                  )
                );
              });
            })
          )
        )
      )
    )
  );

export const updateFloorAssetsLayer = (
  floor: Asset,
  selectedAsset: O.Option<Asset>
) =>
  pipe(
    getAllChildren(floor),
    R.map(filterAssets(ByTag("Managed Assets"))),
    R.chainW((assets: Assets) =>
      pipe(
        R.of(
          toPolygonsGeoJSON(
            assets,
            pipe(selectedAsset, O.map(getId), O.toUndefined)
          )
        ),
        R.chain(getOrAddGeoJSONSource("floor_polygon_source"))
      )
    )
  );

export const showBuildingLabelsLayer = (assets: Assets) =>
  pipe(
    R.of(toPolygonsGeoJSON(assets)),
    R.chain(getOrAddGeoJSONSource("building_label_source")),
    apSecondIO(
      pipe(
        addLayerToMap("building_labels_layer", {
          id: "building_labels_layer",
          type: "symbol",
          source: "building_label_source",
          minzoom: 6,
          paint: {
            "text-color": palette.primary[100],
            "text-halo-color": "hsl(234, 12%, 18%)",
            "text-halo-width": 1,
          },
          layout: {
            "text-field": ["get", "name"],
            "text-font": ["Fira Sans Medium", "Arial Unicode MS Regular"],
          },
        })
      )
    )
  );

export const showAssetsLabelsLayer = (assets: Assets) =>
  pipe(
    R.of(toPolygonsGeoJSON(assets)),
    R.chain(getOrAddGeoJSONSource("assets_label_source"))
  );
const showL =
  <R extends MapboxContext>(
    layer: ACTIVITY_MAP_LAYER,
    onVisibleR: R.Reader<R, IO.IO<void>>,
    onHiddenR: R.Reader<MapboxContext, IO.IO<void>>
  ) =>
  (layers: Set<ACTIVITY_MAP_LAYER>) =>
    pipe(
      layers,
      S.elem(mapLayersEq)(layer),
      b.fold(
        () => onHiddenR,
        () => onVisibleR
      )
    );

export const setupDataVisualizationLayers = pipe(
  pipe(
    addOrUpdateGeoJSONSourceOld("heatmap_source", EmptyGeoJSON),
    chainFirstIO(renderDetailedHeatmap("heatmap_source", 0))
  ),
  chainFirstIO(
    pipe(
      addOrUpdateGeoJSONSourceOld("room_by_room_source", EmptyGeoJSON),
      chainFirstIO(
        pipe(
          addLayerToMap(
            "room_by_room_layer",
            {
              id: "room_by_room_layer",
              type: "fill",
              source: "room_by_room_source",
              layout: {},
              minzoom: 13,
              interactive: false,
              paint: {
                "fill-color": ["get", "utilizationColor"],
                "fill-opacity": 1,
              },
            },
            "all_floor_assets_fill_layer"
          )
        )
      )
    )
  ),
  chainFirstIO(
    pipe(
      R.of(EmptyGeoJSON),
      R.chain(getOrAddGeoJSONSource("assets_label_source")),
      chainFirstIO(
        pipe(
          addLayerToMap("assets_labels_layer", {
            id: "assets_labels_layer",
            type: "symbol",
            source: "assets_label_source",
            minzoom: 6,
            paint: {
              "text-color": palette.primary[100],
              "text-halo-color": "hsl(234, 12%, 18%)",
              "text-halo-width": 1,
            },
            layout: {
              "text-field": ["get", "name"],
              "text-font": ["Fira Sans Medium", "Arial Unicode MS Regular"],
            },
          })
        )
      ),
      apFirstIO(
        addLayerToMap("assets_labels_detailed_layer", {
          id: "assets_labels_detailed_layer",
          type: "symbol",
          source: "assets_label_source",
          minzoom: 6,
          paint: {
            "text-color": palette.primary[100],
            "text-halo-color": "hsl(234, 12%, 18%)",
            "text-halo-width": 1,
          },
          layout: {
            "text-field": ["get", "name"],
            "text-font": ["Fira Sans Medium", "Arial Unicode MS Regular"],
            // The following properties will enable all asset labels to appear
            "text-allow-overlap": true,
            "text-ignore-placement": true,
          },
        })
      )
    )
  )
);

export const showEnabledDataVisualizationLayers = pipe(
  pipe(
    R.asks<{ layers: Set<ACTIVITY_MAP_LAYER> }, Set<ACTIVITY_MAP_LAYER>>(
      (_) => _.layers
    ),
    R.chainW(
      showL(
        ACTIVITY_MAP_LAYER.OCCUPANCY_HEATMAP,
        showLayer("heatmap_source_layer"),
        hideLayer("heatmap_source_layer")
      )
    )
  ),
  chainFirstIO(
    pipe(
      R.asks<MapLayersContext, Set<ACTIVITY_MAP_LAYER>>((_) => _.layers),
      R.chainW(
        showL(
          ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION,
          showLayer("room_by_room_layer"),
          hideLayer("room_by_room_layer")
        )
      )
    )
  )
);

export const setupBuildingBackgroundLayer = addLayerToMap(
  "building_background",
  {
    id: "building_background",
    type: "background",
    layout: { visibility: "none" },
    paint: {
      // "background-pattern": "blueprint-pattern",
      "background-color": "#0f1114",
    },
  }
);

export const updateFloorAssetsLayers =
  (selectedAsset: O.Option<Asset>) => (floor: Asset) =>
    pipe(
      updateFloorAssetLabels(floor),
      chainFirstIO(updateFloorAssetsLayer(floor, selectedAsset)),
      chainFirstIO(
        pipe(
          R.asks<{ layers: Set<ACTIVITY_MAP_LAYER> }, Set<ACTIVITY_MAP_LAYER>>(
            (_) => _.layers
          ),
          R.chainW((layers) =>
            pipe(
              showL(
                ACTIVITY_MAP_LAYER.ZONE_FILL,
                showLayer("all_floor_assets_fill_layer"),
                hideLayer("all_floor_assets_fill_layer")
              )(layers),
              apFirstIO(
                pipe(
                  showL(
                    ACTIVITY_MAP_LAYER.ZONE_OUTLINES,
                    showLayer("all_floor_assets_outline_layer"),
                    hideLayer("all_floor_assets_outline_layer")
                  )
                )(layers)
              ),
              apFirstIO(
                pipe(
                  showL(
                    ACTIVITY_MAP_LAYER.ZONE_LABELS,
                    showLayer("all_floor_assets_id_layer"),
                    hideLayer("all_floor_assets_id_layer")
                  )
                )(layers)
              )
            )
          )
        )
      )
    );

export const setMapToBuildingMode = pipe(
  hideLayer("building_polygon_layer"),
  chainFirstIO(showLayer("building_background")),
  chainFirstIO(hideLayer("building_labels_layer")),
  chainFirstIO(hideLayers(MAPBOX_DEFAULT_LAYERS)),
  chainFirstIO(showLayer(FLOOR_DIAGRAM_LAYER_ID)),
  chainFirstIO(showLayer("assets_labels_layer")),
  chainFirstIO(showLayer("all_floor_assets_fill_layer")),

  chainFirstIO(showLayer("floor_assets_fill_layer")),
  chainFirstIO(showEnabledDataVisualizationLayers)
  // chainFirstIO(showLayer("heatmap_source_layer"))
  // chainFirstIO(showLayer("room_by_room_layer")),
);

export const setMapToMapMode = pipe(
  hideLayer(FLOOR_DIAGRAM_LAYER_ID),
  chainFirstIO(hideLayer("heatmap_source_layer")),
  chainFirstIO(hideLayer("room_by_room_layer")),
  chainFirstIO(hideLayer("building_background")),
  chainFirstIO(hideLayer("assets_labels_layer")),
  chainFirstIO(hideLayer("assets_labels_detailed_layer")),
  chainFirstIO(hideLayer("all_floor_assets_fill_layer")),
  chainFirstIO(hideLayer("all_floor_assets_id_layer")),
  chainFirstIO(hideLayer("all_floor_assets_outline_layer")),
  chainFirstIO(hideLayer("floor_assets_fill_layer")),
  chainFirstIO(showLayers(MAPBOX_DEFAULT_LAYERS)),
  chainFirstIO(hideLayer("poi-label")),
  chainFirstIO(showLayer("building_polygon_layer")),
  chainFirstIO(showLayer("building_labels_layer")),
  chainFirstIO(hideLayer("loading_layer"))
);

export const handleMapClick = (onAssetUnselect: (map: Map) => void) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.on("click", onAssetUnselect);
    })
  );
export const handleMapUnClick = (onAssetUnselect: (map: Map) => void) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.off("click", onAssetUnselect);
    })
  );

export const switchMapMode = (
  selectedFloor: O.Option<Asset>,
  onAssetUnselect: (map: Map) => void
) =>
  pipe(
    selectedFloor,
    O.fold(
      () =>
        pipe(setMapToMapMode, chainFirstIO(handleMapClick(onAssetUnselect))),
      () =>
        pipe(
          setMapToBuildingMode,
          chainFirstIO(handleMapUnClick(onAssetUnselect))
        )
    )
  );

export const updateFloorAssetLabels = (floor: Asset) =>
  pipe(
    getAllChildren(floor),
    R.map(filterAssets(ByTag("Managed Assets"))),
    R.chainW((floorAssets: Assets) =>
      pipe(
        R.asks<MapLayersContext, Set<ACTIVITY_MAP_LAYER>>((_) => _.layers),
        R.chainW((layers) =>
          pipe(
            showL(
              ACTIVITY_MAP_LAYER.LABELS,
              pipe(
                showAssetsLabelsLayer(floorAssets),
                chainFirstIO(showLayer("assets_labels_layer"))
              ),
              hideLayer("assets_labels_layer")
            )(layers),
            apFirstIO(
              showL(
                ACTIVITY_MAP_LAYER.LABELS_DETAILED,
                pipe(
                  showAssetsLabelsLayer(floorAssets),
                  chainFirstIO(showLayer("assets_labels_detailed_layer"))
                ),
                hideLayer("assets_labels_detailed_layer")
              )(layers)
            )
          )
        )
      )
    )
  );

export const setupBuildingLabelsLayer = showBuildingLabelsLayer([]);

export const setupMapLayers = (
  buildingClickHandler: (selectedAsset: O.Option<Asset>) => void
) =>
  pipe(
    setupBuildingLayer("assetId"),
    chainFirstIO(setupClusterLayer),
    chainFirstIO(setupLoadingIndicatorLayer),
    chainFirstIO(setupFloorAssetsLayer(buildingClickHandler)),
    chainFirstIO(setupDataVisualizationLayers),
    chainFirstIO(setupBuildingLabelsLayer)
  );

export const updateBuildingLabelsLayer = (buildings: Assets) =>
  pipe(
    R.asks<MapLayersContext, Set<ACTIVITY_MAP_LAYER>>((_) => _.layers),
    R.chainW(
      showL(
        ACTIVITY_MAP_LAYER.LABELS,
        showBuildingLabelsLayer(buildings),
        hideLayer("building_labels_layer")
      )
    )
    // remove mapbox built in labels
  );

const updateMapLayersIO = (buildings: Assets, selectedAsset: O.Option<Asset>) =>
  pipe(
    updateClusterLayer(buildings),
    chainFirstIO(updateBuildingLayer(buildings, selectedAsset)),
    chainFirstIO(updateBuildingLabelsLayer(buildings))
  );

export const addOrUpdateFloorLayers = (selectedFloor: O.Option<Asset>) =>
  pipe(
    selectedFloor,
    O.fold(() => R.of(noop), addOrUpdateFloorDiagramLayer)
  );

export const getDefaultViewBBox = (
  buildings: Assets,
  selectedBuilding: O.Option<Asset>
) =>
  pipe(
    selectedBuilding,
    O.fold(
      () => pipe(getAssetsBBox(buildings), O.map(scaleBBox(2))),
      (a) => pipe(a, getAssetBBox, O.map(scaleBBox(assetScaleForBBox(a))))
    )
  );

const mapIntro =
  (idleIO: (bbox: BBox) => R.Reader<MapboxContext, IO.IO<void>>) =>
  (bbox: BBox) =>
    pipe(
      R.ask<MapboxContext & MapBoxPaddingOptionsContext>(),
      R.map((ctx) => () => {
        const initialCamera = ctx.map.cameraForBounds(pipe(bbox, scaleBBox(4)));
        ctx.map.jumpTo({ ...initialCamera, pitch: 45 });

        const finalBBox = pipe(bbox, scaleBBox(0.5));
        const camera = ctx.map.cameraForBounds(finalBBox, {
          padding: ctx.padding,
        });

        ctx.map.flyTo({
          ...camera,
          pitch: 0,
          essential: true,
          duration: 3000,
        });

        const reset = (ev: EventData) => {
          ctx.map.stop();
          ctx.map.setPitch(0);
          ctx.map.off("mousedown", reset);
          ctx.map.off("touchstart", reset);
          ctx.map.off("wheel", reset);
          // ctx.map.jumpTo({
          //   ...camera,
          //   pitch: 0,
          //   // padding: ctx.padding,
          // });
          idleIO(finalBBox)(ctx)();
        };
        ctx.map.once("mousedown", reset);
        ctx.map.once("touchstart", reset);
        ctx.map.once("wheel", reset);
      })
    );

export const ActivityMap: React.FC<{}> = () => {
  const [spatialCommandContext, setSpatialCommandContext] = useState<
    O.Option<MapboxContext>
  >(O.none);
  // inidicate that initial intro animation has been executed so to not run it again
  const dispatch = useHistoryControllerDispatch(UIStateController);

  const [floorPlanImage] = useController(
    ActivityMapFloorDiagramDataController,
    floorPlanImageL.get
  );
  const [allAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2)
  );
  const buildings = useMemo(() => pipe(allAssets, getBuildings), [allAssets]);

  const layers = useCollectorOld(
    UIStateController,
    flow(L.composeLens(ActivityPageUIStateL), L.prop("layers"))
  );
  const [utilizationLayerData] = useController(
    ActivityMapDataController,
    UtilizationGeoJSONL.get
  );

  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );
  const [bboxO] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(BboxL)).get
  );
  const [bearingO] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(BearingL)).get
  );
  const { leftNav, rightNav, bottomPanel } = useCollectorOld(
    ActivityPageUICalculationsController,
    L.props("bottomPanel", "leftNav", "rightNav")
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(log("change:floorPlan Image Changed"), [floorPlanImage]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(log("change:layers Changed"), [layers]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(log("change:heatmap geojson"), [occupancyHeatmapLayerData]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(log("change:selected asset"), [selectedAsset]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(log("change:bbox"), [bboxO]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(log("change:bearing"), [bearingO]);

  // const [selectedlevel, setSelectedFloor] = useStableO(selectedAsset);
  // const [selectedBuilding, setSelectedBuilding] = useStableO(selectedAsset);
  // const [selectedLevelAssets, setSelectedFloorAssets] = useStable(
  //   pipe(allAssets, assetsToShow(selectedAsset)),
  //   eqAssetsByUUIDOnly
  // );
  const { selectedLevel, selectedBuilding, selectedLevelAssets } = useCollector(
    SelectedAssetHierarchyCollector,
    L.props("selectedBuilding", "selectedLevel", "selectedLevelAssets")
  );

  // useEffect(() => {
  //   setSelectedFloor(
  //     pipe(
  //       selectedAsset,
  //       O.filter(pipe(isZone, or(isFloor))),
  //       O.chain((a) => getParentAssetOf(isFloor)(a)(allAssets))
  //     )
  //   );
  //   setSelectedFloorAssets(pipe(allAssets, assetsToShow(selectedAsset)));
  //   setSelectedBuilding(
  //     pipe(
  //       selectedAsset,
  //       O.chain((a) => getParentAssetOf(isBuilding)(a)(allAssets))
  //     )
  //   );
  // }, [selectedAsset, allAssets]);

  // useEffect(log("change:selected building"), [selectedBuilding]);
  // useEffect(log("change:selected floor"), [selectedLevel]);
  // useEffect(log("change:selected floor assets"), [selectedLevelAssets]);

  const handleBuildingClick = useCallback(
    (sa: O.Option<Asset>) =>
      pipe(
        flow(
          pipe(
            ActivityPageUIStateL,
            L.composeLens(ActivityPageSelectedAssetIdL)
          ).set(pipe(sa, O.map(getId), A.fromOption)),
          pipe(
            ActivityPageUIStateL,
            L.composeLens(SelectedSideItemL),
            L.modify((d) =>
              O.alt(() => O.some(AssetPageTab(ASSET_CONTEXT_TABS.ASSET)))(d)
            )
          )
        ),
        dispatch
      ),
    [dispatch]
  );

  const handleBuildingUnselect = useCallback(
    (ev: any) => {
      pipe(
        ev.target.queryRenderedFeatures(ev.point, {
          layers: ["building_polygon_layer"],
        }),
        NEA.fromArray,
        O.map(NEA.head),
        O.fold(
          () =>
            pipe(
              pipe(
                ActivityPageUIStateL,
                L.composeLens(ActivityPageSelectedAssetIdL)
              ).set([]),
              dispatch
            ),
          noop
        )
      );
    },
    [dispatch]
  );

  const setBBoxUnlessSet = useCallback(
    (bb: O.Option<BBox>) => {
      dispatch(
        pipe(
          ActivityPageUIStateL,
          L.composeLens(BboxL),
          L.modify(O.alt(() => bb))
        ),
        true
      );
    },
    [dispatch]
  );

  const handleUserInitiatedBBoxChange = useCallback(
    (ev: EventData) => {
      ev.target.once("idle", () => {
        if (!ev.target.isMoving()) {
          dispatch(
            pipe(ActivityPageUIStateL, L.composeLens(BboxL)).set(
              O.some(ev.target.getBounds().toArray().flat() as BBox)
            ),
            true
          );
        }
      });
    },
    [dispatch]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        pipe(
          setupMapLayers(handleBuildingClick),
          chainFirstIO(onBuildingClick(handleBuildingClick))
        )
      )
    ),
    [spatialCommandContext, handleBuildingClick]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        flow(
          withPaddingOptions({
            top: 75,
            left: leftNav + 25,
            right: 400,
            bottom: bottomPanel + 25,
          }),
          pipe(
            bboxO,
            O.fold(
              () =>
                pipe(
                  getDefaultViewBBox(buildings, selectedBuilding),
                  O.fold(
                    () => R.of(noop),
                    pipe(
                      mapIntro((bbox) =>
                        pipe(R.of(() => setBBoxUnlessSet(O.some(bbox))))
                      )
                    )
                  )
                ),
              (bb) =>
                pipe(
                  setMapBBox(
                    pipe(
                      bearingO,
                      O.map(radiansToDegrees),
                      O.getOrElse(() => 0)
                    )
                  )(bb),
                  chainFirstIO(onUserMapMove2(handleUserInitiatedBBoxChange))
                )
            )
          )
        )
      )
    ),
    [
      bboxO,
      bearingO,
      spatialCommandContext,
      buildings,
      selectedBuilding,
      setBBoxUnlessSet,
      handleUserInitiatedBBoxChange,
    ]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        flow(
          withLayersConfig(layers),
          switchMapMode(selectedLevel, handleBuildingUnselect)
        )
      )
    ),
    [selectedLevel, spatialCommandContext, layers, handleBuildingUnselect]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        flow(
          withAssets(allAssets),
          withUtilizationContext(utilizationLayerData),
          updateZoneUtilizationLayer
        )
      )
    ),
    [
      allAssets,
      selectedLevelAssets,
      selectedAsset,
      utilizationLayerData,
      spatialCommandContext,
    ]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        flow(
          withAssets(allAssets),
          withFloorDiagramContext(floorPlanImage),
          addOrUpdateFloorLayers(selectedLevel)
        )
      )
    ),
    [
      allAssets,
      selectedLevelAssets,
      selectedLevel,
      floorPlanImage,
      spatialCommandContext,
    ]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        flow(
          withAssets(allAssets),
          withLayersConfig(layers),
          pipe(
            selectedLevel,
            O.fold(() => R.of(noop), updateFloorAssetsLayers(selectedAsset))
          )
        )
      )
    ),
    [selectedLevel, selectedAsset, allAssets, spatialCommandContext, layers]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        flow(
          withLayersConfig(layers),
          withAssets(allAssets),
          updateMapLayersIO(buildings, selectedAsset)
        )
      )
    ),
    [allAssets, spatialCommandContext, selectedAsset, layers, buildings]
  );

  return (
    <BaseSpatialMap
      style={{
        width: "100%",
        border: "none",
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
      }}
      initalBounds={bboxO}
      onMapReady={(map) => {
        setSpatialCommandContext(
          O.some({
            map,
          })
        );
      }}
    />
  );
};
