import React, { SetStateAction, useCallback } from "react";
import {
  OSMBuildingsCollector,
  OSMBuildingsCollectorState,
  OsmBuildingsL,
} from "controllers/OSMBuildingCollector/OSMBuildingsCollector";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { Stack } from "@mui/material";
import { BiWifi } from "react-icons/all";
import * as TE from "fp-ts/TaskEither";
import { useCollectorOld } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as O from "fp-ts/Option";
import { clog, noop } from "lib/util";
import osmtogeojson from "osmtogeojson";
import { area, bboxPolygon } from "@turf/turf";
import { FeatureCollection } from "geojson";
import * as AD from "lib/at-data/AsyncData";
import { LoadingButton } from "@mui/lab";
import {
  fetchOSMData,
  generateOverpassquery,
  makeBBoxString,
} from "lib/osm-api";
import { BBox } from "lib/at-data/BBox";
import { useCollectorWithDispatch } from "lib/at-react/hooks";

export const getBboxArea = (bbox: BBox) => pipe(bbox, bboxPolygon, area);

export const MAXIMUM_BBOX_AREA = 1000 * 1000; // One Square Kilometer

export const bboxIsLargerThan = (refArea: number) => (bbox: BBox) =>
  pipe(bbox, getBboxArea, (bboxArea) => bboxArea > refArea);

export const loadOsmBuildings =
  (bbox: O.Option<BBox>) =>
  (dispatch: React.Dispatch<SetStateAction<OSMBuildingsCollectorState>>) =>
    pipe(
      bbox,
      O.map(
        flow(
          makeBBoxString,
          generateOverpassquery,
          fetchOSMData,
          TE.apFirstW(
            TE.fromIO(() =>
              pipe(O.none, AD.PendingData, OsmBuildingsL.set, dispatch)
            )
          ),
          TE.map(osmtogeojson),
          TE.chainIOK(
            (features) => () =>
              pipe(
                features as FeatureCollection,
                O.some,
                AD.DoneData,
                OsmBuildingsL.set,
                dispatch
              )
          )
        )
      ),
      O.getOrElseW(() => noop)
    );

export const GeographicalPlaneMenu: React.FC<{}> = (props) => {
  const [osmBuildings, dispatch] = useCollectorWithDispatch(
    OSMBuildingsCollector,
    flow(L.prop("osmBuildings"))
  );
  const bbox = useCollectorOld(
    UIStateController,
    flow(
      L.prop("state"),
      L.prop("luciferPage"),
      L.prop("geographicalWorkspace"),
      L.prop("bbox")
    )
  );

  const handleLoadOsmBuildings = useCallback(loadOsmBuildings(bbox)(dispatch), [
    dispatch,
    bbox,
  ]);

  return (
    <Stack direction={"row"}>
      <LoadingButton
        endIcon={<BiWifi />}
        color={"primary"}
        size={"small"}
        disabled={pipe(
          bbox,
          O.fold(() => true, bboxIsLargerThan(MAXIMUM_BBOX_AREA))
        )}
        loading={AD.isLoading(osmBuildings)}
        variant="contained"
        onClick={handleLoadOsmBuildings}
        aria-label="Reset Georeference"
      >
        Import OSM
      </LoadingButton>
    </Stack>
  );
};
