import {
  Coord,
  eqCoord,
  getX,
  getY,
  toPoint,
} from "lib/at-data/assets/models/Coord";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { aperture } from "fp-ts-std/Array";
import * as O from "fp-ts/Option";
import { applyToPoint, Matrix, translate } from "transformation-matrix";
import { lineAngle, lineLength, lineMidpoint, pointWithLine } from "geometric";
import { BBox } from "./BBox";
import { not, Predicate } from "fp-ts/Predicate";
import { degreesToRadians } from "@turf/turf";
import { gsap } from "gsap";
import * as n from "fp-ts/number";
import * as Ord from "fp-ts/Ord";
import { clog } from "lib/util";
import { sequenceS } from "fp-ts/Apply";

export const pointsToEdges = (polygon: Array<Coord>): Array<Edge> =>
  pipe(
    polygon,
    O.fromPredicate((_) => _.length > 2),
    O.map(
      flow(
        ([first, ...rest]) =>
          pipe(
            [first, ...rest],
            aperture(2),
            A.append([rest[rest.length - 1], first])
          ),
        A.mapWithIndex((i, [edgeA, edgeB]) => ({
          a: { coord: edgeA, id: i },
          b: { coord: edgeB, id: i + 1 > polygon.length - 1 ? 0 : i + 1 },
        }))
      )
    ),
    O.getOrElseW(() => [])
  );

export type Edge = {
  a: {
    id: number;
    coord: Coord;
  };
  b: {
    id: number;
    coord: Coord;
  };
};

export const toLine = (edge: Edge): [[number, number], [number, number]] => [
  [edge.a.coord.x, edge.a.coord.y],
  [edge.b.coord.x, edge.b.coord.y],
];

export const minX2 = ({ a, b }: Edge) => Math.min(a.coord.x, b.coord.x);
export const minY2 = ({ a, b }: Edge) => Math.min(a.coord.y, b.coord.y);
export const maxX2 = ({ a, b }: Edge) => Math.max(a.coord.x, b.coord.x);
export const maxY2 = ({ a, b }: Edge) => Math.max(a.coord.y, b.coord.y);

export const getBBox = (edge: Edge): BBox => [
  minX2(edge),
  minY2(edge),
  maxX2(edge),
  maxY2(edge),
];

export const toSVGRect = (edges: Array<Edge>) =>
  pipe(
    edges,
    A.map(
      ({
        a: {
          coord: { x, y },
        },
      }) => `${x} ${y}`
    )
  ).join(" ");

export const edgeAngle = (edge: Edge) =>
  lineAngle([
    [edge.a.coord.x, edge.a.coord.y],
    [edge.b.coord.x, edge.b.coord.y],
  ]);

export type SimplePolygon = {
  points: [Coord, Coord, Coord, ...Coord[]];
};

export const hasThreeOrMoreEdges: Predicate<Array<Coord>> = (points) =>
  points.length > 2;

export const fromPoints = (rawPoints: Array<Coord>): O.Option<SimplePolygon> =>
  pipe(
    rawPoints,
    O.fromPredicate(hasThreeOrMoreEdges),
    O.map((points) => ({ points } as SimplePolygon))
  );

export const toPoints = (sp: SimplePolygon) => sp.points;

export const updatePoint =
  (id: number, newPoint: Coord) =>
  (sp: SimplePolygon): SimplePolygon =>
    ({
      points: pipe(
        sp.points,
        A.updateAt(id, newPoint),
        O.getOrElseW(() => sp.points)
      ),
    } as SimplePolygon);

export const nextPointId =
  (id: number) =>
  (sp: SimplePolygon): number =>
    id < sp.points.length - 1 ? id + 1 : 0;

export const prevPointId =
  (id: number) =>
  (sp: SimplePolygon): number =>
    id > 0 ? id - 1 : sp.points.length - 1;

export const getEdge =
  (id: number) =>
  (sp: SimplePolygon): Edge => {
    const nextId = pipe(sp, nextPointId(id));
    const pointA = sp.points[id];
    const pointB = sp.points[nextId];
    return {
      a: {
        id,
        coord: pointA,
      },
      b: {
        id: nextId,
        coord: pointB,
      },
    };
  };

export const transformEdge =
  (id: number, transform: Matrix) =>
  (sp: SimplePolygon): SimplePolygon =>
    ({
      points: pipe(
        sp.points,
        A.modifyAt(id, (point) => applyToPoint(transform, point)),
        O.chain(
          A.modifyAt(pipe(sp, nextPointId(id)), (point) =>
            applyToPoint(transform, point)
          )
        ),
        O.getOrElseW(() => sp.points)
      ),
    } as SimplePolygon);

export const extrudeEdge =
  (id: number, extrusionLength: number) =>
  (sp: SimplePolygon): SimplePolygon => {
    const currentEdge = pipe(sp, getEdge(id));
    const currentEdgeAngle = pipe(currentEdge, edgeAngle) + 90 || 0;

    const nextId = pipe(sp, nextPointId(id));

    const pointA = pipe(sp.points[id]);
    const pointB = pipe(sp.points[nextId]);

    const edgeTransformMatrix = translate(
      extrusionLength * Math.cos(degreesToRadians(currentEdgeAngle)),
      extrusionLength * Math.sin(degreesToRadians(currentEdgeAngle))
    );
    return pipe(
      sp.points,
      A.splitAt(nextId),
      ([before, after]) => [...before, pointA, pointB, ...after],
      fromPoints,
      O.map(transformEdge(nextId, edgeTransformMatrix)),
      O.getOrElse(() => sp)
    );
  };

export const simplifyEdges = (sp: SimplePolygon): SimplePolygon =>
  pipe(
    sp.points,
    A.filterWithIndex((i, point) => {
      const previousPoint = sp.points[pipe(sp, prevPointId(i))];
      const thisPoint = sp.points[i];
      const nextPoint = sp.points[pipe(sp, nextPointId(i))];

      // if the next segment is continuation of the current one, we can drop it
      const a = pointWithLine(toPoint(nextPoint), [
        toPoint(previousPoint),
        toPoint(thisPoint),
      ]);

      return !a;
    }),
    fromPoints,
    O.getOrElse(() => sp)
  );

export const toSVG = (points: Array<Coord>) =>
  pipe(
    points,
    A.map(({ x, y }) => `${x} ${y}`)
  ).join(" ");

export const toSVGDef = (sp: SimplePolygon) => pipe(sp.points, toSVG);

export const toEdges = (sp: SimplePolygon): Array<Edge> =>
  pipe(sp.points, pointsToEdges);

export const findNearestPointOnEdge = (point: Coord) => (edge: Edge) => {
  const atob = {
    x: edge.b.coord.x - edge.a.coord.x,
    y: edge.b.coord.y - edge.a.coord.y,
  };
  const atop = { x: point.x - edge.a.coord.x, y: point.y - edge.a.coord.y };
  const len = atob.x * atob.x + atob.y * atob.y;
  const dot = atop.x * atob.x + atop.y * atob.y;
  const t = Math.min(1, Math.max(0, dot / len));

  return { x: edge.a.coord.x + atob.x * t, y: edge.a.coord.y + atob.y * t };
};

// change points for polygon other edges

export const getPolygonDimensions = (polygon: SimplePolygon) => {
  const rightEdge = pipe(
    polygon.points,
    A.reduce(0, (acc, cur) => Math.max(acc, getX(cur)))
  );
  const leftEdge = pipe(
    polygon.points,
    A.reduce(rightEdge, (acc, cur) => Math.min(acc, getX(cur)))
  );
  const bottomEdge = pipe(
    polygon.points,
    A.reduce(0, (acc, cur) => Math.max(acc, getY(cur)))
  );
  const topEdge = pipe(
    polygon.points,
    A.reduce(bottomEdge, (acc, cur) => Math.min(acc, getY(cur)))
  );
  const width = rightEdge - leftEdge;
  const height = bottomEdge - topEdge;
  return { width, height, leftEdge, topEdge, rightEdge, bottomEdge };
};

export const getPolygonSnaps =
  ({ width, height }: { width: number; height: number }) =>
  (snaps: Array<Coord>) => {
    return pipe(
      snaps,
      A.concat(
        pipe(
          snaps,
          A.map(({ x, y }) => ({
            x: x - width,
            y: y - height,
          }))
        )
      )
    );
  };

// export const snapPolygonToPoint = (polygon: Array<Coord>) => (point: Coord) => (snaps: Array<Coord>) =>
export const snapToPointFrom =
  (snaps: Array<Coord>) =>
  (point: Coord, radius: number = 20) =>
    pipe(point, gsap.utils.snap({ values: snaps, radius }));

export const snapToVerticalGuidesFrom =
  (snaps: Array<Coord>) =>
  (point: Coord, radius: number = 10) =>
    pipe(
      point.x,
      gsap.utils.snap({ values: pipe(snaps, A.map(getX)), radius }),
      (snappedTo) => ({ x: snappedTo, y: point.y })
    );

export const snapToHorizontalGuidesFrom =
  (snaps: Array<Coord>) =>
  (point: Coord, radius: number = 10) =>
    pipe(
      point.y,
      gsap.utils.snap({ values: pipe(snaps, A.map(getY)), radius }),
      (snappedTo) => ({ x: point.x, y: snappedTo })
    );

const ordByClosestX = (x: number) =>
  pipe(
    n.Ord,
    Ord.contramap((snap: Coord) => x - snap.x)
  );
const ordByClosestY = (y: number) =>
  pipe(
    n.Ord,
    Ord.contramap((snap: Coord) => y - snap.y)
  );

export const closestSnap = (point: Coord) => (snaps: Array<Coord>) => {
  const s = pipe(snaps, A.filter(not((_) => eqCoord.equals(_, point))));
  return pipe(
    sequenceS(O.Applicative)({
      x: pipe(s, A.sort(ordByClosestX(point.x)), A.head, O.map(getX)),
      y: pipe(s, A.sort(ordByClosestY(point.y)), A.head, O.map(getY)),
    }),
    O.getOrElse(() => point)
  );
};
