import { pipe } from "fp-ts/function";
import { NumberSlice } from "lib/at-data/NumberSlice";
import * as M from "fp-ts/Map";
import { ordUUID } from "lib/at-data/UUID";
import * as R from "fp-ts/Record";

export type ChartDataSlice = Readonly<Record<string, any>> & {
  timestamp: number;
};
/**
 * Given a slice of numbers, get a Record of those counts where key is zone id.
 * TODO: this should return timestamp too probably
 * @param slice
 */
export const indexNumbersByZoneId: (
  slice: NumberSlice
) => Readonly<Record<string, number>> = (slice) => {
  const W = M.getWitherable(ordUUID);
  return W.reduceWithIndex(slice.slices, {}, (key, acc, item) =>
    pipe(acc, R.upsertAt(key, item))
  );
};
export const upsertTimestamp: (
  timestamp: Date
) => (record: Readonly<Record<string, number>>) => ChartDataSlice =
  (timestamp) => (record) =>
    pipe(
      record,
      R.upsertAt("timestamp", timestamp.valueOf())
    ) as ChartDataSlice;
/**
 * Given a slice of numbers, get a Record of those counts where key is zone id. Also with a special key "timestamp"
 * TS type system doesn't allow to express this sufficiently well
 * This is useful for feeding to Recharts
 * @param slice
 */
export const indexNumbersByZoneIdWithTimestamp: (
  slice: NumberSlice
) => ChartDataSlice = (slice) => {
  return pipe(slice, indexNumbersByZoneId, upsertTimestamp(slice.timestamp));
};
