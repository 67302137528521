import { ASSETS_SOURCE_ID } from "components/spatial/base/layers/assets";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import { featureCollection, polygon } from "@turf/turf";
import palette from "theme/palette";
import * as R from "fp-ts/lib/Reader";
import * as IO from "fp-ts/lib/IO";
import { AnyLayer, AnySourceData } from "mapbox-gl";
import { addOrUpdateLayer } from "lib/fp-mapbox/layers";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { addOrUpdateGeoJSONSourceOld } from "lib/fp-mapbox/sources/geojson";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetsContext } from "contexts/AssetsContext";
import { getGeometry } from "lib/at-data/assets/getters";

export const ASSET_FILLED_POLYGON_LAYER_ID = "asset_filled_polygon_layer";
export const ASSET_OUTLINED_POLYGON_LAYER_ID = "asset_outlined_polygon_layer";
export const addLayerToMap = (id: string, layer: AnyLayer, before?: string) =>
  pipe(R.ask<MapboxContext>(), R.map(addOrUpdateLayer(id, layer, before)));

export const setLayerPointerOnHover =
  (cursor: CSSStyleDeclaration["cursor"]) => (layerId: string) =>
    pipe(
      R.ask<MapboxContext>(),
      R.map((ctx) => () => {
        ctx.map.on("mouseenter", layerId, () => {
          ctx.map.getCanvas().style.cursor = cursor;
        });

        ctx.map.on("mouseleave", layerId, () => {
          ctx.map.getCanvas().style.cursor = "";
        });
      })
    );

export const showAssetFilledPolygonLayer = addOrUpdateLayer(
  ASSET_FILLED_POLYGON_LAYER_ID,
  {
    id: ASSET_FILLED_POLYGON_LAYER_ID,
    type: "fill",
    source: ASSETS_SOURCE_ID,
    layout: {},
    paint: {
      "fill-color": palette.primary[400],
      "fill-opacity": 0.5,
    },
  }
);

export const hideAssetFilledPolygonLayer = pipe(
  R.ask<MapboxContext>(),
  R.map((ctx) => () => {
    ctx.map.setLayoutProperty(
      ASSET_FILLED_POLYGON_LAYER_ID,
      "visibility",
      "none"
    );
  })
);

export const showAssetOutlinedPolygonLayer = addOrUpdateLayer(
  ASSET_OUTLINED_POLYGON_LAYER_ID,
  {
    id: ASSET_OUTLINED_POLYGON_LAYER_ID,
    type: "line",
    source: ASSETS_SOURCE_ID,
    layout: {},
    paint: {
      "line-color": palette.primary[400],
      "line-width": 2,
    },
  }
);

export const hideAssetOutlinedPolygonLayer = pipe(
  R.ask<MapboxContext>(),
  R.map((ctx) => () => {
    ctx.map.setLayoutProperty(
      ASSET_OUTLINED_POLYGON_LAYER_ID,
      "visibility",
      "none"
    );
  })
);

export const showAssetPolygon =
  (asset: Asset): R.Reader<MapboxContext & AssetsContext, IO.IO<void>> =>
  (ctx) =>
  () => {
    addOrUpdateGeoJSONSourceOld(
      ASSETS_SOURCE_ID,
      pipe(
        pipe(
          asset,
          getGeometry,
          O.map(flow(A.of, polygon, A.of, featureCollection))
        ),
        O.getOrElse(() => featureCollection([]))
      )
    )(ctx)();
    showAssetFilledPolygonLayer(ctx)();
  };

export const showAssetOutline =
  (asset: Asset): R.Reader<MapboxContext, IO.IO<void>> =>
  (ctx) =>
  () => {
    addOrUpdateGeoJSONSourceOld(
      ASSETS_SOURCE_ID,
      pipe(
        pipe(
          asset,
          getGeometry,
          O.map(flow(A.of, polygon, A.of, featureCollection))
        ),
        O.getOrElse(() => featureCollection([]))
      )
    )(ctx)();
    showAssetOutlinedPolygonLayer(ctx)();
  };
