import { feature, polygon } from "@turf/turf";
import { Feature, Geometry, Polygon } from "geojson";
import { Asset } from "lib/at-data/assets/Asset";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import { getGeometry } from "lib/at-data/assets/getters";

export const ASSETS_SOURCE_ID = "assets";

export const GeoJSONAssetOld =
  <O>(asset: Asset, observation: O) =>
  (geometry: Geometry) =>
    feature(geometry, { ...asset, ...observation });

export type AssetFeature<T> = Feature<Polygon, T & { asset: Asset }>;

export const GeoJSONAsset = <Ob>(asset: Asset, observation: Ob) =>
  pipe(
    asset,
    getGeometry,
    O.map((g) => polygon(A.of(g), { asset, ...observation }))
  );
