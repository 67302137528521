import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import { Coord, CoordModel, eqCoord } from "lib/at-data/assets/models/Coord";
import * as C from "io-ts/Codec";
import { pipe } from "fp-ts/function";
import { sequenceS } from "fp-ts/Apply";
import * as O from "fp-ts/Option";
import {
  applyToPoint,
  compose,
  flipX,
  fromTriangles,
  inverse,
  Matrix,
} from "transformation-matrix";
import { GeoCoord } from "lib/at-data/GeoCoord";
import { toWgs84 } from "@turf/projection";

export const GeoPointTupleModel = C.struct({
  plan: CoordModel,
  geo: CoordModel,
});

export const GeoReferenceModel = C.array(GeoPointTupleModel);

export const OtterGeoReferenceModel = C.struct({
  x1: GeoPointTupleModel,
  x2: GeoPointTupleModel,
  x3: GeoPointTupleModel,
});

export type GeoReference = C.TypeOf<typeof GeoReferenceModel>;

export const GeoReference = (
  localPoints: Array<Coord>,
  geoPoints: Array<Coord>
) =>
  A.zipWith(localPoints, geoPoints, (plan, geo) => ({
    plan,
    geo,
  }));
export const GeoReference2 = (
  localPoints: Array<[number, number]>,
  geoPoints: Array<[number, number]>
): GeoReference =>
  A.zipWith(localPoints, geoPoints, (plan, geo) => ({
    plan: { x: plan[0], y: plan[1] },
    geo: { x: geo[0], y: geo[1] },
  }));

export const eqGeoReference = A.getEq(
  Eq.struct({
    plan: eqCoord,
    geo: eqCoord,
  })
);
export const convertOtterGeoReference = (
  otter: C.TypeOf<typeof OtterGeoReferenceModel>
): GeoReference => [otter.x1, otter.x2, otter.x3];

export const converToOtter = (georeference: GeoReference) =>
  pipe(
    sequenceS(O.Monad)({
      x1: pipe(georeference, A.lookup(0)),
      x2: pipe(georeference, A.lookup(1)),
      x3: pipe(georeference, A.lookup(2)),
    })
  );

export const mapGeoPoints =
  (f: (a: Coord[]) => Coord[]) =>
  (geoReference: GeoReference): GeoReference =>
    GeoReference(
      pipe(
        geoReference,
        A.map((_) => _.plan)
      ),
      pipe(
        geoReference,
        A.map((_) => _.geo),
        f
      )
    );

/**
 * We take an array of point pairs and then calculate transformation that can be applied to any other point to
 * reproject from one plane to another
 */
export const calculateAffineTransform = (
  geoReference: GeoReference
): Matrix => {
  const localPoints = pipe(
    geoReference,
    A.map((_) => _.plan)
  );
  const mapPoints = pipe(
    geoReference,
    A.map((_) => _.geo)
  );
  return compose(fromTriangles(localPoints, mapPoints), flipX());
};

export const reprojectCoord =
  (transform: Matrix) =>
  (coord: Coord): GeoCoord => {
    const targetX = coord.x * transform.a + coord.y * transform.c + transform.e;
    const targetY = coord.x * transform.b + coord.y * transform.d + transform.f;
    return pipe([targetX, targetY] as const, toWgs84) as [number, number];
  };
export const reprojectCoord2 =
  (transform: Matrix) =>
  (coord: [number, number]): GeoCoord => {
    return applyToPoint(transform, coord);
  };
export const projectPoly =
  (geoReference: GeoReference) => (poly: Array<Coord>) =>
    pipe(poly, A.map(reprojectCoord(calculateAffineTransform(geoReference))));

export const projectToGeo = (geoReference: GeoReference) =>
  pipe(geoReference, calculateAffineTransform, reprojectCoord2);

export const projectToPlan = (geoReference: GeoReference) =>
  pipe(geoReference, calculateAffineTransform, inverse, reprojectCoord2);
