import {
  CameraForBoundsOptions,
  CameraOptions,
  LngLatBoundsLike,
} from "mapbox-gl";
import * as R from "fp-ts/Reader";
import * as IO from "fp-ts/IO";
import { bbox } from "@turf/turf";
import { Feature } from "geojson";
import { pipe } from "fp-ts/function";
import {
  MapboxContext,
  MapBoxPaddingOptionsContext,
} from "lib/fp-mapbox/MapboxContext";
import { BBox, BBoxEq } from "lib/at-data/BBox";

export const toBounds = (feature: Feature): LngLatBoundsLike =>
  pipe(feature, bbox) as [number, number, number, number];

export const fitBounds =
  (bounds: LngLatBoundsLike): R.Reader<MapboxContext, IO.IO<void>> =>
  (ctx) =>
  () => {
    ctx.map.fitBounds(bounds, { maxDuration: 1000 });
  };

export const setMapBBox = (bearing: number) => (bounds: BBox) =>
  pipe(
    R.ask<MapboxContext & MapBoxPaddingOptionsContext>(),
    R.map(({ map, padding }) => () => {
      const mapBBox = map.getBounds().toArray().flat() as BBox;
      if (!BBoxEq.equals(bounds, mapBBox)) {
        const camera = map.cameraForBounds(bounds, { padding, bearing });
        camera &&
          map.jumpTo({
            ...camera,
          });
      }
    })
  );

export const cameraForBounds = (
  bounds: BBox,
  options?: CameraForBoundsOptions
) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      const camera = ctx.map.cameraForBounds(bounds, options);
      camera && ctx.map.jumpTo(camera);
    })
  );

export const jumpTo = (options: CameraOptions) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.jumpTo(options);
    })
  );
export const fitBoundsSmooth =
  (bounds: LngLatBoundsLike): R.Reader<MapboxContext, IO.IO<void>> =>
  (ctx) =>
  () => {
    ctx.map.fitBounds(bounds);
  };
