import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { addAsset } from "lib/at-data/assets/modify";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as L from "monocle-ts/Lens";
import { MakeADT, MakeADTMember, makeMatchP } from "ts-adt/MakeADT";

export type RecordModification<T extends Record<string, unknown>> = {
  [K in keyof T]: {
    property: K;
    value: T[K];
  };
}[keyof T];

export type AssetModification = RecordModification<Asset>;

export type AssetAction = MakeADT<
  "action",
  {
    create: { id: UUID; type: AssetTypes };
    delete: { id: UUID };
    modifyProperty: {
      id: UUID;
      modification: AssetModification;
    };
  }
> & { timestamp: Date };

export type AssetModificationAction = MakeADTMember<
  "action",
  AssetAction,
  "modifyProperty"
>;

export type AssetCreationAction = MakeADTMember<
  "action",
  AssetAction,
  "create"
>;

export const makeAssetModification =
  <K extends keyof Asset>(property: K) =>
  (value: Asset[K]) => ({ value, property });

export const name = makeAssetModification("name");
export const parent = makeAssetModification("parent");

export const poly = makeAssetModification("poly");
export const capacity = makeAssetModification("capacity");
export const tags = makeAssetModification("tags");

export const createAsset = (id: UUID, type: AssetTypes): AssetAction => ({
  action: "create",
  id,
  type,
  timestamp: new Date(),
});

export const generateRandomUUID = (): UUID => {
  // @ts-ignore
  return window.crypto.randomUUID();
};

export const createAssetWithModifications = (
  id: UUID,
  type: AssetTypes,
  modifications: AssetModification[]
): AssetAction[] => [
  {
    action: "create",
    id,
    type,
    timestamp: new Date(),
  },
  ...pipe(modifications, A.map(modifyAsset(id))),
];

export const modifyAsset =
  (id: UUID) =>
  (modification: AssetModification): AssetAction => ({
    id,
    action: "modifyProperty",
    modification,
    timestamp: new Date(),
  });
export const modifyAssetProperties =
  (id: UUID) =>
  (modifications: AssetModification[]): AssetAction[] =>
    pipe(
      modifications,
      A.map((modification) => ({
        id,
        action: "modifyProperty",
        modification,
        timestamp: new Date(),
      }))
    );

export const deleteAsset = (id: UUID): AssetAction => ({
  id,
  action: "delete",
  timestamp: new Date(),
});

/**
 * Note: optimized, since this needs to be fast   /VZ
 * @param commands
 */
export const applyAll =
  (commands: Array<AssetAction>) => (assets: Array<Asset>) => {
    const assetMap = new Map<string, Asset>(
      assets.map((asset) => [asset.id, asset])
    );

    for (const command of commands) {
      const asset = assetMap.get(command.id);

      // eslint-disable-next-line default-case
      switch (command.action) {
        case "create":
          assetMap.set(command.id, addAsset(command.id, O.none, command.type));
          break;
        case "delete":
          if (asset) {
            assetMap.delete(command.id);
          }
          break;
        case "modifyProperty":
          if (asset) {
            assetMap.set(
              command.id,
              pipe(
                asset,
                pipe(L.id<Asset>(), L.prop(command.modification.property)).set(
                  command.modification.value
                )
              )
            );
          }
          break;
      }
    }

    return Array.from(assetMap.values());
  };

export const isCreate = (a: AssetAction): a is AssetCreationAction =>
  a.action === "create";
export const isDelete = (a: AssetAction) => a.action === "delete";
export const isPropertyModify = (
  a: AssetAction
): a is AssetModificationAction => a.action === "modifyProperty";

export const getAllCreateRequests = (actions: Array<AssetAction>) =>
  pipe(actions, A.filter(isCreate));
export const getAllDeleteRequests = (actions: Array<AssetAction>) =>
  pipe(actions, A.filter(isDelete));

export const getAllPropertyChangeRequests = (actions: Array<AssetAction>) =>
  pipe(actions, A.filter(pipe(isPropertyModify)));

export const eqAssetActionByAssetId = pipe(
  eqUUID,
  Eq.contramap((a: AssetAction) => a.id)
);

export type ServerActions = {
  createActions: Array<AssetAction>;
  modifyActions: Array<AssetAction>;
  deleteActions: Array<AssetAction>;
};

/**
 * simplifies the asset actions to the smallest number of server requests by
 * filtering out actions that cancel each other out
 * @param actions
 */
export const toServerActions = (actions: Array<AssetAction>): ServerActions => {
  const createRequests = pipe(actions, getAllCreateRequests);
  const deleteRequests = pipe(actions, getAllDeleteRequests);
  const modificationRequests = pipe(actions, getAllPropertyChangeRequests);
  const newAssetModificationRequests = pipe(
    modificationRequests,
    A.intersection(eqAssetActionByAssetId)(createRequests)
  );

  const createActions = pipe(
    createRequests,
    A.difference(eqAssetActionByAssetId)(deleteRequests),
    A.concat(newAssetModificationRequests)
  );

  const modifyActions = pipe(
    modificationRequests,
    A.difference(eqAssetActionByAssetId)(newAssetModificationRequests),
    A.difference(eqAssetActionByAssetId)(deleteRequests)
  );

  const deleteActions = pipe(
    deleteRequests,
    A.difference(eqAssetActionByAssetId)(createRequests)
  );

  return {
    createActions,
    modifyActions,
    deleteActions,
  };
};

export const ByActionId = (id: UUID) => (action: AssetAction) =>
  eqUUID.equals(id, action.id);

const matchProperty = makeMatchP("property");
export const matchActionP = makeMatchP("action");
