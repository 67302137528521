import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import { polygon } from "@turf/helpers";
import { takeRight } from "fp-ts-std/String";
import { Asset } from "lib/at-data/assets/Asset";

export const getId = (asset: Asset) => asset.id;
export const getTags = (asset: Asset) => asset.tags;
export const getLegacyZoneId = (asset: Asset) => asset.legacyZoneId;
export const getParentId = (asset: Asset) => asset.parent;
export const getName = (asset: Asset) => asset.name;
export const getGeoReference = (asset: Asset) => asset.geoReference;
export const getCenter = (asset: Asset) => asset.center;
export const getArea = (asset: Asset) => asset.area;
export const getCapacity = (asset: Asset) => asset.capacity;
export const getGeometry = (asset: Asset) => asset.geometry;
export const getPoly = (asset: Asset) => asset.poly;
export const getPolygon = (asset: Asset) =>
  pipe(asset, getGeometry, O.map(flow(A.of, polygon)));
export const getDiagram = (asset: Asset) => asset.diagram;
export const getAssetType = (asset: Asset) => asset.type;
export const getAssetCostPerSqM = (asset: Asset) => asset.costPerSqM;
export const getOperatingHours = (asset: Asset) => asset.operationHours;
export const getTargetUtilization = (asset: Asset) => asset.targetUtilization;
export const lastFourCharacters = (s: string) => pipe(s, takeRight(4));
