import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
} from "@mui/material";
import { bbox, toMercator } from "@turf/turf";
import { BBox } from "geojson";
import Icon, { AtEyeOpen, AtGlobe } from "components/ui/Icon";
import {
  addAssetActions,
  AssetActionsController,
} from "controllers/AssetsController/AssetActionsController";
import {
  LegacyAssetsModificationsController,
  modifyGeography,
  modifyGeoreference,
} from "controllers/AssetsController/AssetsController";
import {
  createAssetWithModifications,
  generateRandomUUID,
  modifyAsset,
  parent,
  poly,
} from "controllers/AssetsController/Modification";
import {
  ImageArtifactsController,
  ImageArtifactsControllerComponent,
} from "controllers/FloorBackgroundImagesController/FloorBackgroundImagesController";
import { BackgroundArtifactsL } from "controllers/FloorBackgroundImagesController/lens";
import { SelectedAssetHierarchyCollector } from "controllers/SelectedAssetHierarchyCollector/SelectedAssetHierarchyCollector";
import {
  InitialGeoReferenceL,
  LocalWorkSpaceModeL,
  LuciferPagePageUIStateL,
} from "controllers/UIStateCollector/lens/luciferPageL";
import {
  LocalWorkspaceMode,
  LuciferPageUIState,
} from "controllers/UIStateCollector/models/LuciferPageUIStateModel";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { ByAllAssetIdsOnly } from "controllers/UIStateCollector/utils";
import { useStable, useStableEffect } from "fp-ts-react-stable-hooks";
import { sequenceT } from "fp-ts/Apply";
import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as n from "fp-ts/number";
import * as O from "fp-ts/Option";
import * as Ord from "fp-ts/Ord";
import { not } from "fp-ts/Predicate";
import * as R from "fp-ts/Reader";
import { ArtifactTypes, LambentArtifact } from "lib/at-data/Artifact";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import { Assets, getByParent } from "lib/at-data/assets/assets";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { ById, filterAssets } from "lib/at-data/assets/filters";
import {
  getGeoReference,
  getId,
  getPoly,
  getPolygon,
} from "lib/at-data/assets/getters";
import { AssetGeoReferenceL, GeographyL, PolyL } from "lib/at-data/assets/lens";
import { eqAssetByUUID } from "lib/at-data/assets/models/AssetModel";
import { Coord } from "lib/at-data/assets/models/Coord";
import { GeoReference } from "lib/at-data/assets/models/GeoReferenceModel";
import { dropAssets, updateAsset } from "lib/at-data/assets/modify";
import * as AD2 from "lib/at-data/AsyncData2";
import { GeoCoord } from "lib/at-data/GeoCoord";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { useCollector, useCollectorDispatch } from "lib/at-react/hooks";
import { noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import * as T from "monocle-ts/Traversal";
import React, { useCallback, useState } from "react";
import { BiGlobe, BiWifi, BsFillCloudSlashFill, BsPlus } from "react-icons/all";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import palette from "theme/palette";
import { applyToPoints, inverse } from "transformation-matrix";
import { getFloorLabel } from "views/authenticated/activity/page/components/activityControls/ActivityControlsFloorPickerMenu";
import { ordByNavOrder } from "views/authenticated/activity/page/components/activityDrawer/ActivityDrawerList";
import { PanScaleRotateControl } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/LevelAssetProperties";
import {
  WORKSPACE_HEIGHT,
  WORKSPACE_WIDTH,
} from "views/authenticated/lucifer/consts";
import { getThreePlanePoints } from "views/authenticated/lucifer/lib";
import { GeographicalPlaneMenu } from "views/authenticated/lucifer/modules/GeographicalWorkspace/GeographicalPlaneMenu";
import { GeographicalWorkspace } from "views/authenticated/lucifer/modules/GeographicalWorkspace/GeographicalWorkspace";
import { AddNewPolygon } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/layers/components/AddNewPolygon";
import { EditablePolygon } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/layers/components/EditablePolygon";
import { PolygonReferenceLayer } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/layers/PolygonReferenceLayer";
import { ReferenceLayers } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/layers/ReferenceLayers";
import {
  WorkingLayer,
  ZonePolygon,
} from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/layers/WorkingLayer";
import { LevelDiagram } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/LevelDiagram";
import { PrintWorkspace } from "views/authenticated/lucifer/modules/PrintWorkspace";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";

export const Level: React.FC<{ level: Asset }> = (props) =>
  pipe(
    props.level.floorMeta,
    O.fold(
      () => <div>need meta</div>,
      () => <LevelDiagram level={props.level} />
    )
  );

export const GeographicPolygonRow: React.FC<{
  geography: O.Option<Array<GeoCoord>>;
  visible: boolean;
  onClearClick: () => void;
  onVisibilityOnToggle: () => void;
  onVisibilityOffToggle: () => void;
}> = (props) => (
  <ListItem
    style={{
      backgroundColor: "inherit",
    }}
  >
    <ListItemAvatar>
      <Avatar
        style={{
          backgroundColor: O.isNone(props.geography)
            ? palette.status.error.dark
            : "",
        }}
      >
        <Icon icon={AtGlobe} />
      </Avatar>
    </ListItemAvatar>

    <ListItemText primary={"Building Outline"} />

    <IconButton
      onClick={() =>
        props.visible
          ? props.onVisibilityOffToggle()
          : props.onVisibilityOnToggle()
      }
      aria-label="Hide Level Diagram"
      size={"large"}
    >
      <Icon
        icon={AtEyeOpen}
        color={props.visible ? "white" : palette.neutral["600"]}
      />
    </IconButton>
    <IconButton
      onClick={props.onClearClick}
      aria-label="Clear Building Geometry"
      size={"large"}
    >
      <Icon
        icon={BsFillCloudSlashFill}
        color={props.visible ? "white" : palette.neutral["600"]}
      />
    </IconButton>
  </ListItem>
);

export const LevelRow: React.FC<{
  level: Asset;
  visible: boolean;
  selected: boolean;
  onClick: (level: Asset) => void;
  onVisibilityOnToggle: (id: UUID) => void;
  onVisibilityOffToggle: (id: UUID) => void;
}> = (props) => (
  <ListItem
    style={{
      backgroundColor: props.selected ? palette.neutral["450"] : "inherit",
    }}
  >
    <ListItemAvatar>
      <Avatar
        style={{
          backgroundColor: O.isNone(props.level.floorMeta)
            ? palette.status.error.dark
            : "",
        }}
      >
        {pipe(
          props.level.floorMeta,
          O.map(getFloorLabel),
          O.getOrElseW(() => "?")
        )}
      </Avatar>
    </ListItemAvatar>

    <ListItemText
      primary={props.level.name}
      secondary={pipe(
        props.level.floorMeta,
        O.chain((_) => _.abbreviation),
        O.foldW(
          () => null,
          (abbr) => `Abbreviation: ${abbr}`
        )
      )}
      onClick={() => props.onClick(props.level)}
    />

    <IconButton
      onClick={() =>
        props.visible
          ? props.onVisibilityOffToggle(props.level.id)
          : props.onVisibilityOnToggle(props.level.id)
      }
      aria-label="Hide Level Diagram"
      size={"large"}
    >
      <Icon
        icon={AtEyeOpen}
        color={
          props.visible || props.selected ? "white" : palette.neutral["600"]
        }
      />
    </IconButton>
  </ListItem>
);

export const OrdByLevelDifference = (level: number) =>
  pipe(
    O.getOrd(n.Ord),
    Ord.contramap((b: Asset) =>
      pipe(
        b.floorMeta,
        O.map((_) => Math.abs(level - _.order))
      )
    )
  );

export const getClosestGeoreferencedLevel =
  (selectedLevel: Asset) => (peerLevels: Assets) =>
    pipe(
      peerLevels,
      A.filter(flow(getGeoReference, O.isSome)),
      A.sort(
        OrdByLevelDifference(
          pipe(
            selectedLevel.floorMeta,
            O.map((_) => _.order),
            O.getOrElse(() => 0)
          )
        )
      ),
      A.head
    );

export const useBuildingPolygonForGeoreferenceDefaults =
  (building: Asset) =>
  ([localPlaneWidth, localPlaneHeight]: [number, number]) => {
    const localPlaneBBox = [0, 0, localPlaneWidth, -localPlaneHeight] as BBox;

    const localO = pipe(localPlaneBBox, getThreePlanePoints, O.some);
    const geoO = pipe(
      pipe(building, getPolygon, O.map(toMercator)),
      O.map(flow(bbox, getThreePlanePoints))
    );

    return pipe(
      sequenceT(O.Monad)(localO, geoO),
      O.map(([local, geo]) => GeoReference(local, geo))
    );
  };

export const getSuggestedGeoReference =
  (
    selectedLevel: Asset,
    peerLevels: O.Option<Assets>,
    buildingO: O.Option<Asset>
  ) =>
  (planeImage: O.Option<LambentArtifact>) =>
    pipe(
      planeImage,
      O.chain((pi) =>
        pipe(
          peerLevels,
          O.chain(getClosestGeoreferencedLevel(selectedLevel)),
          O.chain(getGeoReference),
          O.alt(() =>
            pipe(
              buildingO,
              O.chain((building) =>
                useBuildingPolygonForGeoreferenceDefaults(building)([
                  pi.planeWidth,
                  pi.planeHeight,
                ])
              )
            )
          )
        )
      )
    );

export const LevelGeoreferenceIndicatorButton: React.FC<{
  level: Asset;
  onApply: (level: Asset, georeference: O.Option<GeoReference>) => void;
}> = ({ level, onApply }) => {
  const { selectedBuildingLevels, selectedBuilding } = useCollector(
    SelectedAssetHierarchyCollector,
    L.props("selectedBuildingLevels", "selectedBuilding", "selectedLevel")
  );
  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );
  const image = pipe(backgroundImageArtifacts, getSliceByUUID(level.id));

  const suggestedGeoreference = pipe(
    image,
    getSuggestedGeoReference(level, selectedBuildingLevels, selectedBuilding)
  );

  const handleApplyGeoReference = useCallback(() => {
    onApply(level, suggestedGeoreference);
  }, [level, suggestedGeoreference, onApply]);
  const handleClearGeoReference = useCallback(() => {
    onApply(level, O.none);
  }, [level, onApply]);
  // const handleCV = useCallback(() => {
  //   pipe(
  //     image,
  //     O.fold(
  //       () => {},
  //       (fp) => {
  //         const offScreenCanvas = document.createElement("canvas");
  //         offScreenCanvas.width = fp.planeWidth;
  //         offScreenCanvas.height = fp.planeHeight;
  //
  //         const ctx = offScreenCanvas.getContext("2d");
  //
  //         const img = new Image();
  //         img.onload = () => {
  //           if (ctx) {
  //             ctx.drawImage(img, 0, 0);
  //
  //             const src = cv.imread(offScreenCanvas);
  //
  //             cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
  //             cv.Canny(src, src, 50, 200, 3);
  //
  //             const lines = new cv.Mat();
  //             cv.HoughLines(src, lines, 1, Math.PI / 180, 80, 30, 10);
  //
  //             console.log(lines.rows);
  //             for (let i = 0; i < lines.rows; i += 1) {
  //               const rho = lines.data32F[i * 2];
  //               const theta = lines.data32F[i * 2 + 1];
  //               const a = Math.cos(theta);
  //               const b = Math.sin(theta);
  //               const x0 = a * rho;
  //               const y0 = b * rho;
  //               const startPoint = { x: x0 - 1000 * b, y: y0 + 1000 * a };
  //               const endPoint = { x: x0 + 1000 * b, y: y0 - 1000 * a };
  //               console.log("Line", startPoint, endPoint);
  //               // cv.line(dst, startPoint, endPoint, [255, 0, 0, 255]);
  //             }
  //           }
  //         };
  //         img.src = fp.data;
  //
  //         // cv.imshow(this.cannyEdgeRef.current, edges);
  //       }
  //     )
  //   );
  // }, [image]);

  return pipe(
    level,
    getGeoReference,
    O.fold(
      () => (
        <IconButton
          onClick={handleApplyGeoReference}
          aria-label="Apply Suggested Georeference"
          size={"large"}
        >
          <Icon
            size={"large"}
            icon={BiGlobe}
            color={
              O.isSome(suggestedGeoreference)
                ? palette.status.warning.dark
                : palette.status.error.dark
            }
          />
        </IconButton>
      ),
      () => (
        <>
          <IconButton
            onClick={handleClearGeoReference}
            aria-label="Asset Georeferencing Status"
            size={"large"}
          >
            <Icon
              size={"large"}
              icon={BiGlobe}
              color={palette.status.success.dark}
            />
          </IconButton>
          {/*<Button onClick={handleCV}>Test</Button>*/}
        </>
      )
    )
  );
};

export const GeoreferenceControl: React.FC<{ level: Asset }> = (props) => {
  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );
  const image = pipe(backgroundImageArtifacts, getSliceByUUID(props.level.id));
  return (
    <div
      style={{
        width: 200,
        height: 300,
        position: "absolute",
        right: 50,
        bottom: 50,
        zIndex: 1000,
        backgroundColor: palette.neutral["700"],
      }}
    >
      <PanScaleRotateControl onClick={() => {}} disabled={false} />
    </div>
  );
};

export const LocalPlaneMenu: React.FC<{
  selectedLayer: Asset;
  handleApplyGeoReference: (
    level: Asset,
    georeference: O.Option<GeoReference>
  ) => void;
}> = (props) => {
  const uiStateDispatch = useHistoryControllerDispatch(UIStateController);
  const [allAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2)
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);

  const handleAddZone = useCallback(() => {
    uiStateDispatch(
      pipe(LuciferPagePageUIStateL, L.composeLens(LocalWorkSpaceModeL)).set(
        LocalWorkspaceMode.ADD_POLYGON
      ),
      true
    );
  }, [uiStateDispatch]);
  const handleDrop = useCallback(() => {
    pipe(
      props.selectedLayer,
      getByParent,
      R.map(flow(dropAssets, rootDispatch))
    )(allAssets);
  }, [rootDispatch, allAssets, props.selectedLayer]);

  return (
    <Stack direction={"row"}>
      {
        <LevelGeoreferenceIndicatorButton
          onApply={props.handleApplyGeoReference}
          level={props.selectedLayer}
        />
      }
      <Button
        endIcon={<BiWifi />}
        color={"primary"}
        size={"small"}
        onClick={handleAddZone}
        aria-label="Reset Georeference"
      >
        Add Polygon
      </Button>
      <Button
        color={"error"}
        size={"small"}
        onClick={handleDrop}
        aria-label="Reset Georeference"
      >
        Drop All Floor Zones
      </Button>
    </Stack>
  );
};

export const TabMenu: React.FC<{
  tab: string;
  onTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  selectedLayer: Asset;
  handleApplyGeoReference: (
    level: Asset,
    georeference: O.Option<GeoReference>
  ) => void;
}> = ({ tab, children, onTabChange, ...rest }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ backgroundColor: palette.neutral["600"] }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          value={tab}
          onChange={onTabChange}
          aria-label="Building Editor Tabs"
        >
          <Tab label="Local" value={"0"} />
          <Tab label="Geographical" value={"1"} />
          <Tab label="Worksheet" value={"2"} />
        </TabList>
      </Box>
      <Box>
        {tab === "0" && <LocalPlaneMenu {...rest} />}
        {tab === "1" && <GeographicalPlaneMenu />}
      </Box>
    </Stack>
  );
};

export const LocalWorkSpace: React.FC<{
  selectedLevel: Asset;
  visibleLevelIds: Array<UUID>;
  geopolygonVisible: boolean;
}> = ({ selectedLevel, visibleLevelIds, geopolygonVisible }) => {
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const {
    selectedBuildingLevels,
    selectedBuilding,
    selectedLevelAssets,
    selectedZone,
  } = useCollector(
    SelectedAssetHierarchyCollector,
    L.props(
      "selectedBuildingLevels",
      "selectedBuilding",
      "selectedLevel",
      "selectedLevelAssets",
      "selectedZone"
    )
  );
  const initialGeoReference = useCollectorOld(
    UIStateController,
    L.composeLens(
      pipe(LuciferPagePageUIStateL, L.composeLens(InitialGeoReferenceL))
    )
  );

  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );
  const image = pipe(
    backgroundImageArtifacts,
    getSliceByUUID(selectedLevel.id)
  );

  const mode = useCollectorOld(
    UIStateController,
    L.composeLens(
      pipe(LuciferPagePageUIStateL, L.composeLens(LocalWorkSpaceModeL))
    )
  );
  const uiStateDispatch = useControllerDispatch(UIStateController);
  const assetActionsDispatch = useCollectorDispatch(AssetActionsController);

  const handleSelectZone = useCallback(
    (zone: Asset) => {
      uiStateDispatch(
        pipe(
          LuciferPagePageUIStateL,
          L.composeLens(
            pipe(L.id<LuciferPageUIState>(), L.prop("selectedAssetIds"))
          )
        ).set(A.of(zone.id))
      );
    },
    [uiStateDispatch]
  );
  const handleChangeZonePolygon = useCallback(
    (newPolygon: Array<Coord>) =>
      pipe(
        selectedZone,
        O.map(getId),
        O.fold(noop, (selectedZoneId) => {
          pipe(
            poly(O.some(newPolygon)),
            modifyAsset(selectedZoneId),
            A.of,
            addAssetActions,
            assetActionsDispatch
          );
        })
      ),
    [selectedZone, assetActionsDispatch]
  );

  const handleAddZone = useCallback(
    (newPolygon: Array<Coord>) => {
      const newId = generateRandomUUID();
      pipe(
        createAssetWithModifications(newId, AssetTypes.ZONE, [
          poly(O.some(newPolygon)),
          parent(O.some(selectedLevel.id)),
        ]),
        addAssetActions,
        assetActionsDispatch
      );

      pipe(
        flow(
          pipe(LuciferPagePageUIStateL, L.composeLens(LocalWorkSpaceModeL)).set(
            LocalWorkspaceMode.EDIT
          ),
          pipe(
            LuciferPagePageUIStateL,
            L.composeLens(
              pipe(L.id<LuciferPageUIState>(), L.prop("selectedAssetIds"))
            )
          ).set(A.of(newId))
        ),
        uiStateDispatch
      );
    },
    [selectedLevel, rootDispatch, uiStateDispatch]
  );

  const sugestedGeoreference = pipe(
    image,
    getSuggestedGeoReference(
      selectedLevel,
      selectedBuildingLevels,
      selectedBuilding
    )
  );
  return (
    <TransformWrapper
      maxScale={15}
      initialScale={0.75}
      minScale={0.125}
      centerOnInit={true}
      centerZoomedOut={true}
    >
      <TransformComponent
        wrapperStyle={{
          width: WORKSPACE_WIDTH,
          height: WORKSPACE_HEIGHT,
        }}
      >
        {
          <div
            style={{
              position: "relative",
              width: WORKSPACE_WIDTH * 3,
              height: WORKSPACE_WIDTH * 3,
              backgroundSize: "20px 20px",
              backgroundImage:
                "radial-gradient(circle, #777 1px, rgba(0,0,0, 0) 1px)",
            }}
          >
            <WorkingLayer
              disabled={false}
              level={selectedLevel}
              referenceGeoReference={initialGeoReference}
              render={(fp, localMatrix) => {
                return (
                  <>
                    {pipe(
                      selectedLevelAssets,
                      O.map(
                        A.filter(
                          not((zone) =>
                            pipe(eqUUID, O.getEq).equals(
                              O.some(zone.id),
                              pipe(selectedZone, O.map(getId))
                            )
                          )
                        )
                      ),
                      O.map(
                        A.map((zone) => (
                          <ZonePolygon
                            localMatrix={localMatrix}
                            onClick={handleSelectZone}
                            zone={zone}
                            key={zone.id}
                          />
                        ))
                      ),
                      O.getOrElseW(() => null)
                    )}

                    {mode === LocalWorkspaceMode.EDIT &&
                      pipe(
                        selectedZone,
                        O.chain(getPoly),
                        O.map((newPolygon) =>
                          applyToPoints(localMatrix, newPolygon)
                        ),
                        O.map((newPolygon) => (
                          <EditablePolygon
                            onChanged={(modifiedPolygon) => {
                              handleChangeZonePolygon(
                                applyToPoints(
                                  inverse(localMatrix),
                                  modifiedPolygon
                                )
                              );
                            }}
                            onDuplicate={(newPolygon2) =>
                              handleAddZone(
                                applyToPoints(inverse(localMatrix), newPolygon2)
                              )
                            }
                            points={newPolygon}
                            snaps={pipe(
                              selectedLevelAssets,
                              O.map(
                                flow(
                                  A.filterMap(
                                    flow(
                                      getPoly,
                                      O.map((polygon2) =>
                                        applyToPoints(localMatrix, polygon2)
                                      )
                                    )
                                  ),
                                  A.flatten
                                )
                              ),
                              O.getOrElseW(() => [])
                            )}
                          />
                        )),
                        O.getOrElseW(() => null)
                      )}
                    {mode === LocalWorkspaceMode.ADD_POLYGON && (
                      <AddNewPolygon
                        onDone={(newPolygon) =>
                          handleAddZone(
                            applyToPoints(inverse(localMatrix), newPolygon)
                          )
                        }
                        snaps={pipe(
                          selectedLevelAssets,
                          O.map(
                            flow(
                              A.filterMap(
                                flow(
                                  getPoly,
                                  O.map((polygon2) =>
                                    applyToPoints(localMatrix, polygon2)
                                  )
                                )
                              ),
                              A.flatten
                              // A.map(({ x, y }) => ({
                              //   x: x / ratio,
                              //   y: y / ratio,
                              // }))
                            )
                          ),
                          O.getOrElseW(() => [])
                        )}
                      />
                    )}
                  </>
                );
              }}
            />

            {geopolygonVisible &&
              pipe(
                selectedBuilding,
                O.foldW(
                  () => null,
                  (sb) => (
                    <PolygonReferenceLayer
                      level={selectedLevel}
                      geoReference={pipe(
                        initialGeoReference,
                        O.alt(() => sugestedGeoreference)
                      )}
                      asset={sb}
                    />
                  )
                )
              )}
            <ReferenceLayers
              level={selectedLevel}
              geoReference={pipe(
                initialGeoReference,
                O.alt(() => sugestedGeoreference)
              )}
              layers={pipe(
                selectedBuildingLevels,
                O.map(
                  filterAssets(
                    ByAllAssetIdsOnly(
                      pipe(visibleLevelIds, A.append(selectedLevel.id))
                    )
                  )
                ),
                O.getOrElseW(() => [])
              )}
            />
          </div>
        }
      </TransformComponent>
    </TransformWrapper>
  );
};

export const LevelManagement: React.FC<{
  selectedBuilding: Asset;
  selectedLevel: Asset;
  selectedBuildingLevels: Assets;
  selectedLevelAssets: Assets;
}> = (props) => {
  const [selectedWorkspace, setSelectedWorkspace] = React.useState("0");

  const uiStateDispatch = useControllerDispatch(UIStateController);

  const [visibleLevelIds, visibleLevelIdsDispatch] = useStable<Array<UUID>>(
    [],
    A.getEq(eqUUID)
  );
  const [geopolygonVisible, setGeopolygonVisible] = useState(true);
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );

  const handleChangeWorkspace = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setSelectedWorkspace(newValue);
  };

  const handleVisibilityOnToggle = useCallback(
    (id: UUID) => visibleLevelIdsDispatch(A.append(id)),
    [visibleLevelIdsDispatch]
  );
  const handleVisibilityOffToggle = useCallback(
    (id: UUID) => visibleLevelIdsDispatch(A.difference(eqUUID)(A.of(id))),
    [visibleLevelIdsDispatch]
  );

  const handleSelectLevel = useCallback(
    (level: Asset) => {
      uiStateDispatch(
        pipe(
          LuciferPagePageUIStateL,
          L.composeLens(
            pipe(L.id<LuciferPageUIState>(), L.prop("selectedAssetIds"))
          )
        ).set(A.of(level.id))
      );
      pipe(
        props.selectedLevel,
        getGeoReference,
        pipe(LuciferPagePageUIStateL, L.composeLens(InitialGeoReferenceL)).set,
        uiStateDispatch
      );
    },
    [uiStateDispatch, props.selectedLevel]
  );
  useStableEffect(
    () =>
      pipe(
        props.selectedLevel,
        getGeoReference,
        pipe(LuciferPagePageUIStateL, L.composeLens(InitialGeoReferenceL)).set,
        uiStateDispatch
      ),
    [props.selectedLevel],
    Eq.tuple(eqAssetByUUID)
  );

  const handlePrintLevelsWorksheet = useCallback(() => {}, []);

  const rootDispatch = useControllerDispatch(RawAssetsController);

  const handleApplyGeoReference = useCallback(
    (level: Asset, georeference: O.Option<GeoReference>) => {
      assetModificationsDispatch(modifyGeoreference(level, georeference));
      pipe(
        ById(level.id),
        updateAsset,
        T.composeLens(AssetGeoReferenceL),
        T.set(georeference),
        rootDispatch
      );
      pipe(
        georeference,
        pipe(LuciferPagePageUIStateL, L.composeLens(InitialGeoReferenceL)).set,
        uiStateDispatch
      );
    },
    [assetModificationsDispatch, rootDispatch, uiStateDispatch]
  );

  const handleClearBuildingGeometry = useCallback(() => {
    assetModificationsDispatch(
      flow(modifyGeography(props.selectedBuilding, O.none))
    );
    rootDispatch(
      flow(
        pipe(
          ById(props.selectedBuilding.id),
          updateAsset,
          T.composeLens(GeographyL),
          T.set(O.none as O.Option<Array<GeoCoord>>)
        )
      )
    );
  }, [props.selectedBuilding, rootDispatch, assetModificationsDispatch]);

  return (
    <ImageArtifactsControllerComponent
      context={{
        artifactType: ArtifactTypes.BACKGROUND,
        visibleAssets: props.selectedBuildingLevels,
      }}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        height={"100%"}
        minHeight={"920px"}
        alignItems="stretch"
        justifyContent={"space-between"}
      >
        <Stack flex={1} style={{ backgroundColor: palette.neutral["700"] }}>
          <List sx={{ width: "100%", color: palette.neutral["400"] }}>
            <GeographicPolygonRow
              onClearClick={handleClearBuildingGeometry}
              geography={props.selectedBuilding.geometry}
              key={"geo_poly"}
              onVisibilityOffToggle={() => setGeopolygonVisible(false)}
              onVisibilityOnToggle={() => setGeopolygonVisible(true)}
              visible={geopolygonVisible}
            />
            {pipe(
              props.selectedBuildingLevels,
              A.sort(ordByNavOrder),
              A.map((level) => (
                <LevelRow
                  onClick={handleSelectLevel}
                  selected={eqAsset.equals(level, props.selectedLevel)}
                  key={level.id}
                  level={level}
                  onVisibilityOnToggle={handleVisibilityOnToggle}
                  onVisibilityOffToggle={handleVisibilityOffToggle}
                  visible={visibleLevelIds.includes(level.id)}
                />
              ))
            )}
          </List>
          <Stack sx={{ p: 1 }}>
            <Button color={"primary"} startIcon={<BsPlus />}>
              Add Floor
            </Button>
          </Stack>
        </Stack>
        <Stack flex={4}>
          <TabContext value={selectedWorkspace.toString()}>
            <Box sx={{ width: "100%" }}>
              <TabMenu
                onTabChange={handleChangeWorkspace}
                tab={selectedWorkspace}
                selectedLayer={props.selectedLevel}
                handleApplyGeoReference={handleApplyGeoReference}
              />
              <TabPanel
                value={"0"}
                style={{ padding: 0, position: "relative" }}
              >
                <LocalWorkSpace
                  selectedLevel={props.selectedLevel}
                  geopolygonVisible={geopolygonVisible}
                  visibleLevelIds={visibleLevelIds}
                />
              </TabPanel>
              <TabPanel value={"1"} style={{ padding: 0 }}>
                <GeographicalWorkspace building={props.selectedBuilding} />
              </TabPanel>
              <TabPanel value={"2"} style={{ padding: 0 }}>
                <PrintWorkspace building={props.selectedBuilding} />
              </TabPanel>
            </Box>
          </TabContext>
        </Stack>
      </Stack>
    </ImageArtifactsControllerComponent>
  );
};
