import React, { useCallback, useState } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { flow, pipe } from "fp-ts/function";
import { validateLevel } from "lib/at-data/assets/validations/validateLevel";
import {
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import * as O from "fp-ts/Option";
import { InvalidAssetIndicator } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/InvalidAssetIndicator";
import { LevelFloorMetaPropertiesRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/LevelFloorMetaPropertiesRow";
import {
  calculateAffineTransform,
  GeoReference,
} from "lib/at-data/assets/models/GeoReferenceModel";
import Icon, {
  AtMoveLeft,
  AtMoveRight,
  AtMoveUp,
  AtMoveDown,
  AtRotateLeft,
  AtRotateRight,
  AtScaleUp,
  AtScaleDown,
} from "components/ui/Icon";
import { BsGlobe } from "react-icons/all";
import { useControllerDispatch } from "lib/at-react/defineController";
import { RawAssetsController } from "views/authenticated/root/AssetsController";
import { ById } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import { AssetGeoReferenceL } from "lib/at-data/assets/lens";
import * as A from "fp-ts/Array";
import { decomposeTSR } from "transformation-matrix";
import { toPoint } from "lib/at-data/assets/models/Coord";
import {
  centroid,
  coordAll,
  getCoord,
  multiPoint,
  toMercator,
  toWgs84,
  transformRotate,
  transformScale,
  transformTranslate,
} from "@turf/turf";
import { Feature, MultiPoint } from "geojson";
import {
  rad2deg,
  toCoord,
} from "views/authenticated/activity/page/components/spatial";
import {
  ClearableProperty,
  OptionalProperty,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import palette from "theme/palette";
import styled from "styled-components";

export const LevelAssetProperties: React.FC<{ asset: Asset }> = (props) => {
  const validLevel = pipe(props.asset, validateLevel);
  return (
    <>
      <TableRow
        key={"assetLevel"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          Asset Class
        </TableCell>
        <TableCell align="right">
          <strong>Level</strong>
          {/*Troy: this needs better icon*/}
          <InvalidAssetIndicator validation={validLevel} />
        </TableCell>
      </TableRow>
      <LevelFloorMetaPropertiesRow asset={props.asset} />
      <GeoReferencePropertyRow asset={props.asset} />
    </>
  );
};

export const modifyAroundCentroidOrigin =
  (
    transform: (
      planOffset: number
    ) => (feature: Feature<MultiPoint>) => Feature<MultiPoint>
  ) =>
  (geoReference: GeoReference): GeoReference => {
    const planPoints = pipe(
      geoReference,
      A.map((_) => _.plan)
    );
    const planAngle = pipe(
      geoReference,
      calculateAffineTransform,
      decomposeTSR,
      (_) => _.rotation.angle,
      rad2deg
    );

    const geoPoints = pipe(
      geoReference,
      A.map(flow((_) => _.geo, toPoint)),
      multiPoint,
      toWgs84,
      transform(planAngle),
      toMercator,
      coordAll,
      A.map(flow((_) => toCoord(_)))
    );

    return GeoReference(planPoints, geoPoints);
  };

const METERS = 0.001;

export const moveLeft =
  (multiplier: number) =>
  (planOffset: number) =>
  (points: Feature<MultiPoint>) =>
    transformTranslate(
      points,
      METERS * multiplier,
      -Math.abs(planOffset) + 270
    );

export const moveRight =
  (multiplier: number) =>
  (planOffset: number) =>
  (points: Feature<MultiPoint>) =>
    transformTranslate(points, METERS * multiplier, -Math.abs(planOffset) + 90);

export const moveUp =
  (multiplier: number) =>
  (planOffset: number) =>
  (points: Feature<MultiPoint>) =>
    transformTranslate(points, METERS * multiplier, -Math.abs(planOffset));

export const moveDown =
  (multiplier: number) =>
  (planOffset: number) =>
  (points: Feature<MultiPoint>) =>
    transformTranslate(
      points,
      METERS * multiplier,
      -Math.abs(planOffset) + 180
    );

export const scaleUp =
  (unit: number) => (planOffset: number) => (points: Feature<MultiPoint>) =>
    transformScale(points, 1 + 0.1 * unit, { origin: "center" });
export const scaleDown =
  (unit: number) => (planOffset: number) => (points: Feature<MultiPoint>) =>
    transformScale(points, 1 / (1 + 0.1 * unit), { origin: "center" });

export const rotateLeft =
  (unit: number) => (planOffset: number) => (points: Feature<MultiPoint>) =>
    transformRotate(points, 1 * unit, {
      pivot: pipe(points, centroid, getCoord),
    });
export const rotateRight =
  (unit: number) => (planOffset: number) => (points: Feature<MultiPoint>) =>
    transformRotate(points, -1 * unit, {
      pivot: pipe(points, centroid, getCoord),
    });

export const NORMAL = 1;
export const FINE = 0.2;
export const COARSE = 9;

export const PanScaleRotateControl: React.FC<{
  onClick: (transform: (gr: GeoReference) => GeoReference) => void;
  disabled: boolean;
}> = (props) => {
  const [precisionMultiplier, setPrecisionMultiplier] = useState(NORMAL);
  const handleChange = useCallback(
    (transform: (gr: GeoReference) => GeoReference) => (ev: never) =>
      props.onClick(transform),
    [props.onClick]
  );

  return (
    <Stack justifyContent={"space-around"} alignItems={"center"}>
      <Stack>
        <ToggleButtonGroup
          size={"small"}
          color="primary"
          value={precisionMultiplier}
          exclusive
          onChange={(ev, nv) => setPrecisionMultiplier(nv)}
          aria-label="Precision"
        >
          <ToggleButton value={FINE} disabled={props.disabled}>
            Fine
          </ToggleButton>
          <ToggleButton value={NORMAL} disabled={props.disabled}>
            Normal
          </ToggleButton>
          <ToggleButton value={COARSE} disabled={props.disabled}>
            Coarse
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Stack sx={{ width: 150, height: 150, margin: "32px auto" }}>
        <FloorAdjuster>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(scaleDown(precisionMultiplier))
            )}
          >
            <Icon icon={AtScaleDown} size={"32px"} />
          </AdjusterButton>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(moveUp(precisionMultiplier))
            )}
          >
            <Icon icon={AtMoveUp} size={"32px"} />
          </AdjusterButton>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(scaleUp(precisionMultiplier))
            )}
          >
            <Icon icon={AtScaleUp} size={"32px"} />
          </AdjusterButton>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(moveLeft(precisionMultiplier))
            )}
          >
            <Icon icon={AtMoveLeft} size={"32px"} />
          </AdjusterButton>
          <span>&nbsp;</span>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(moveRight(precisionMultiplier))
            )}
          >
            <Icon icon={AtMoveRight} size={"32px"} />
          </AdjusterButton>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(rotateLeft(5 * precisionMultiplier))
            )}
          >
            <Icon icon={AtRotateLeft} size={"32px"} />
          </AdjusterButton>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(moveDown(precisionMultiplier))
            )}
          >
            <Icon icon={AtMoveDown} size={"32px"} />
          </AdjusterButton>
          <AdjusterButton
            disabled={props.disabled}
            onClick={handleChange(
              modifyAroundCentroidOrigin(rotateRight(5 * precisionMultiplier))
            )}
          >
            <Icon icon={AtRotateRight} size={"32px"} />
          </AdjusterButton>
        </FloorAdjuster>
      </Stack>
    </Stack>
  );
};

export const AdjusterButton = styled("button")`
  width: 48px;
  height: 48px;
  margin: 1px;
  background: white;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: rgba(104, 91, 199, 0.08);
  }
  & > svg {
    pointer-events: none;
  }
  & > svg path {
    pointer-events: none;
    fill: gray;
  }
  &:hover > svg path {
    fill: #685bc7;
  }
`;

const FloorAdjuster = styled("div")`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  // background-color: #34495e;
  // color: #fff;
  // border: 6px solid #2c3e50;
  // border-radius: 10px;

  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  //
  // & > * {
  //   display: inline-flex;
  //   justify-content: center;
  //   align-items: center;
  //   align-content: center;
  // }
  // & > * > svg {
  //   margin: auto !important;
  // }
`;

export const GeoReferencePropertyRow: React.FC<{ asset: Asset }> = (props) => {
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const handleChangeGeoReference = useCallback(
    (transform: (gr: GeoReference) => GeoReference) =>
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(AssetGeoReferenceL),
          T.modify(O.map(transform))
        )
      ),
    [props.asset]
  );
  const handleClearGeoReference = useCallback(() => {
    rootDispatch(
      pipe(
        ById(props.asset.id),
        updateAsset,
        T.composeLens(AssetGeoReferenceL),
        T.modify((t) => O.none)
      )
    );
  }, [props.asset]);

  return (
    <>
      <TableRow
        key={"geoReference"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          ESPG 3857 Geo Reference
        </TableCell>
        <TableCell align="right">
          <OptionalProperty
            value={props.asset.geoReference}
            required={true}
            component={GeoReferenceProperty(handleClearGeoReference)}
          />
        </TableCell>
      </TableRow>
      <TableRow key={"geoReference2"}>
        <TableCell scope="row" colSpan={2}>
          <PanScaleRotateControl
            onClick={handleChangeGeoReference}
            disabled={O.isNone(props.asset.geoReference)}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export const GeoReferenceProperty =
  (onClear: () => void): React.FC<{ value: GeoReference }> =>
  (props) =>
    (
      <ClearableProperty
        onClear={onClear}
        component={<Icon icon={BsGlobe} color={palette.status.success.light} />}
      />
    );
