import { DataSlice } from "./DataSlice";
import * as M from "fp-ts/Map";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { ordUUID } from "lib/at-data/UUID";
import { UUIDSlice } from "lib/at-data/UUIDSlice";

export type NumberSlice = DataSlice<number>;

export const of: (dataSlice: DataSlice<number>) => NumberSlice = (slice) => ({
  ...slice,
  slices: slice.slices,
});

export const toArray: (slice: NumberSlice) => Array<number> = (slice) =>
  pipe(
    slice.slices,
    M.toArray(ordUUID),
    A.map((data) => data[1])
  );

export const sum: (slice: NumberSlice) => number = (slice) =>
  pipe(
    slice.slices,
    M.reduce(ordUUID)(0, (acc, val) => acc + val)
  );

export const max: (slice: UUIDSlice<number>) => number = (slice) =>
  pipe(slice.slices, M.reduce(ordUUID)(0, Math.max));
